// Importation des modules et composants nécessaires
import React from 'react';
import './css/DataCard.css';
import MetadataQuality from './MetadataQuality';
import { getRelativeDate } from './utils/functions';

const imageDatagences = '../img/datagencesbretagne.svg'; // chemin vers l'image du logo Datagences

/**
 * Interface représentant les propriétés des données pour le composant DataCardDatagences.
 */
interface DataCardDatagencesProps {
  nom_cube: string;
  thematique: string;
  date_maj: string;
  qualite: string;
  searchValue?: string;
  onClick: () => void;
}

/**
 * Met en surbrillance le texte correspondant à la valeur de recherche.
 * @param text - Le texte à afficher.
 * @param highlight - Le texte à mettre en surbrillance.
 * @returns Le texte avec les parties en surbrillance.
 */
const highlightText = (text: string, highlight: string) => {
  if (typeof text !== 'string' || typeof highlight !== 'string') {
    return text; // retourne le texte tel quel si l'un des paramètres n'est pas une chaîne de caractères
  }

  const parts = text.split(new RegExp(`(${highlight})`, 'gi')); // sépare le texte selon la valeur de recherche
  return parts.map((part, index) => {
    if (part.toLowerCase() === highlight.toLowerCase()) {
      return <strong key={index} style={{ backgroundColor: 'orange' }}>{part}</strong>; // met en surbrillance la partie du texte qui correspond à la recherche
    } else {
      return part;
    }
  });
}

/**
 * Composant représentant une carte de données pour Datagences (un résultat Datagences).
 * @param props - Les propriétés du composant, incluant les données de la carte et les fonctions de gestion d'événements.
 * @return Le composant DataCardDatagences.
 */
const DataCardDatagences: React.FC<DataCardDatagencesProps> = ({ nom_cube, thematique, date_maj, qualite, searchValue = '', onClick }) => {

  // calcule le critère de qualité de la carte
  const qualityValue = parseInt(qualite, 10);

  // vérifie si les champs du critère de qualité sont renseignés
  const fields = {
    'Nom du cube': !!nom_cube,
    'Thématique': !!thematique,
    'Date de dernière mise à jour': !!date_maj
  };

  return (
    <div onClick={onClick} className="data-card fade-in"> {/* Gère le clic sur la carte */}
      <h3 className="data-card-datagences-title">{highlightText(nom_cube, searchValue)}</h3> {/* Titre du cube avec mise en surbrillance */}
      <div className="data-card-info-datagences">
        <div>
          <span><strong>Thématique :</strong> {highlightText('Datagences', searchValue)}</span><br />
          <span className="data-card-thematique"><strong>Base de données :</strong> {highlightText(thematique, searchValue)}</span> {/* Thématique de la base de données */}
          <span><strong>Date de dernière mise à jour :</strong> {getRelativeDate(date_maj)}</span><br /> {/* Date de dernière mise à jour formatée de manière relative */}
          <div className="data-card-progress-container-datagences">
            <MetadataQuality quality={qualityValue} fields={fields} /> {/* Affiche la qualité des métadonnées */}
            <span className="data-card-progress-date">À jour</span> {/* Indique que les données sont à jour */}
            <img src={imageDatagences} alt="Logo Datagences Bretagne" className="data-card-img-datagences" /> {/* Affiche le logo de Datagences */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCardDatagences;
