import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import axios, { AxiosError } from 'axios';
import MetadataQuality from './MetadataQuality';
import './css/DataSheet.css';
import TopBar from './TopBar';
import MapPreview from './MapPreview';
import { formatDate, getRelativeDate, formatPhoneNumber, convertLicenceValueToText, formatNumber } from './utils/functions';

const API_URL = process.env.REACT_APP_API_URL;


/**
 * Interface représentant les propriétés des données pour le champ "Études publiées".
 */
interface EtudePubliee {
  url: string;
  title: string;
}

/**
 * Composant représentant la fiche de données.
 * @return Le composant DataSheet.
 */
const DataSheet: React.FC = () => {
  // Extraction du paramètre nomRessource depuis l'URL
  const { nomRessource } = useParams<{ nomRessource: string }>();
  
  // États pour gérer les données de la ressource, le chargement, et les erreurs
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tablePreview, setTablePreview] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
  const [pageTitles, setPageTitles] = useState<{ 
    lien_donnees_brut: string; 
    lien_dictionnaire: string; 
    site_fournisseur: string; 
    site_producteur: string; 
    etudes_publiees: EtudePubliee[]; 
  }>({ 
    lien_donnees_brut: '', 
    lien_dictionnaire: '', 
    site_fournisseur: '', 
    site_producteur: '', 
    etudes_publiees: [] 
  });

  useEffect(() => {
    /**
     * Récupération des données de la ressource.
     */
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/getDataFor/${nomRessource}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setData(response.data); // stocke les données récupérées
      } catch (err) {
        const error = err as AxiosError;
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 404) {
          setError('La ressource demandée n\'existe pas.'); // gère l'erreur si la ressource n'existe pas
        }
      }
    };
  
    fetchData();
  }, [nomRessource]); // relance l'effet à chaque changement de nomRessource

  useEffect(() => {
    /**
     * Récupération d'un aperçu de la table de la ressource.
     */
    const fetchTablePreview = async () => {
      try {
        const response = await axios.get(`${API_URL}/getTablePreview/${nomRessource}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data.length > 0) {
          setColumns(Object.keys(response.data[0])); // enregistre les noms des colonnes
        }
        setTablePreview(response.data); // enregistre l'aperçu de la table
      } catch (err) {
        const error = err as AxiosError;
        console.error('Error fetching table preview:', error);
        if (error.response && error.response.status === 404) {
          setError('La table demandée n\'existe pas.'); // gère l'erreur si la table n'existe pas
        }
      }
    };

    fetchTablePreview();
  }, [nomRessource]); // relance l'effet à chaque changement de nomRessource

  /**
   * Récupère le titre d'une page web.
   * @param url - L'URL de la page web.
   * @return Le titre de la page.
   */
  const fetchPageTitle = async (url: string): Promise<string> => {
    try {
      const response = await axios.get(`${API_URL}/getPageTitle`, {
        params: { url }
      });
      return response.data.title; // retourne le titre de la page
    } catch (error) {
      console.error('Error fetching page title:', error);
      return url; // retourne l'URL si le titre ne peut être récupéré
    }
  };

  useEffect(() => {
    /**
     * Récupération des titres des pages web pour chaque champ.
     */
    const fetchTitles = async () => {
      const promises: Promise<void>[] = [];
  
      // récupération des titres pour chaque champ spécifique
      if (data.lien_donnees_brut) {
        promises.push(
          fetchPageTitle(data.lien_donnees_brut).then(title => {
            setPageTitles(prevState => ({ ...prevState, lien_donnees_brut: title }));
          })
        );
      }
      if (data.lien_dictionnaire) {
        promises.push(
          fetchPageTitle(data.lien_dictionnaire).then(title => {
            setPageTitles(prevState => ({ ...prevState, lien_dictionnaire: title }));
          })
        );
      }
      if (data.site_fournisseur) {
        promises.push(
          fetchPageTitle(data.site_fournisseur).then(title => {
            setPageTitles(prevState => ({ ...prevState, site_fournisseur: title }));
          })
        );
      }
      if (data.site_producteur) {
        promises.push(
          fetchPageTitle(data.site_producteur).then(title => {
            setPageTitles(prevState => ({ ...prevState, site_producteur: title }));
          })
        );
      }
      if (Array.isArray(data.etudes_publiees)) {
        const etudesPromises = data.etudes_publiees.map((etude: string) => 
          fetchPageTitle(etude).then(title => ({ url: etude, title }))
        );
        const etudesTitles = await Promise.all(etudesPromises);
        setPageTitles(prevState => ({ ...prevState, etudes_publiees: etudesTitles }));
      }
  
      await Promise.all(promises); // attend que toutes les promesses soient résolues
      setLoading(false); // arrête l'affichage du chargement
    };
  
    if (data) {
      fetchTitles(); // lance la récupération des titres si les données sont disponibles
    }
  }, [data]);

  /**
   * Affiche un message après 3 secondes de chargement.
   */
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoadingMessage("Oups, la table est un petit peu lourde... Juste le temps d'aller faire un tour à la machine à café 😉");
      }, 3000);
  
      return () => clearTimeout(timer); // nettoie le timer lorsqu'il n'est plus nécessaire
    }
  }, [loading]);

  /**
   * Gère l'ouverture du fichier avec Excel.
   */
  const handleOpenWithExcel = async () => {
    try {
      const response = await axios.get(`${API_URL}/generateODC/${nomRessource}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-office' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nomRessource}.odc`);
      document.body.appendChild(link);
      link.click(); // déclenche le téléchargement du fichier
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading ODC file:', error);
      setError('Error downloading ODC file');
    }
  };

  /**
   * Gère l'ouverture du fichier avec QGIS.
   */
  const handleOpenWithQGIS = async () => {
    try {
      const response = await axios.get(`${API_URL}/generateQGS/${nomRessource}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nomRessource}.qgs`);
      document.body.appendChild(link);
      link.click(); // déclenche le téléchargement du fichier
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading QGS file:', error);
      setError('Error downloading QGS file');
    }
  };

  if (loading) {
    return (
      <div>
        <TopBar context="Home" nomRessource='' />
        <div className="loading-container">
          <CircularProgress /> {/* Affiche une animation de chargement */}
          {loadingMessage && <p className="loading-message">{loadingMessage}</p>}
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div>
        <TopBar context="Home" nomRessource='' />
        <p className="no-data-error-message">{error}</p> {/* Affiche un message d'erreur si quelque chose a mal tourné */}
      </div>
    );
  }
  
  if (!data) {
    return (
      <div>
        <TopBar context="Home" nomRessource='' />
        <p className="no-data-error-message">Aucune donnée disponible</p> {/* Affiche un message si les données sont absentes */}
      </div>
    );
  }

  // calcule le critère de qualité de la carte
  const qualityValue = parseInt(data.qualite, 10);

  // vérifie si les champs du critère de qualité sont renseignés
  const fields = {
    'Titre': !!data.titre,
    'Mots-clés': !!data.mots_cles && data.mots_cles.length > 0,
    'Description': !!data.description,
    'Emprise spatiale': !!data.emprise_spatiale,
    'Niveau de détail le plus fin': !!data.echelle_min,
    'Date de dernière mise à jour': !!data.date_last_maj,
    'Millésimes': !!data.millesimes,
    'Fournisseur': !!data.nom_fournisseur,
    'Producteur': !!data.nom_producteur,
    'Référent(s)': !!data.nom_ce_aud
  };

  return (
    <div>
      <TopBar context="DataSheet" nomRessource={data.nom_ressource} />
      <div className="data-sheet">
        <div className="header">
          <div className="header-left">
            <h3 className="data-sheet-title">
              {data.titre || data.nom_ressource}
              <span className="data-sheet-objects-count">{formatNumber(data.nb_objets)} objets</span>
            </h3>
            {data.mots_cles && (
              <div className="tags">
                {data.mots_cles.map((tag: string, index: number) => (
                  <span className="tag" key={index}>{tag}</span>
                ))}
              </div>
            )}
            {data.description && <p className="data-sheet-subtitle">{data.description}</p>}
            {data.subtilites && (
              <p className="data-sheet-subtilites" style={{ marginTop: '-5px' }}>
                <strong>Subtilité(s) :</strong> {data.subtilites}
              </p>
            )}
          </div>
          <div className="header-right">
            <div className="tags">
              <strong><span className="tag">{data.nom_ressource}</span></strong>
            </div>
            <div className="header-buttons-row">
              <button className="open-with-button" onClick={handleOpenWithExcel}><span>Ouvrir avec Excel</span><img src='../img/excel.svg' className="open-with-button-img" /></button>
              {data.type_geo && <button className="open-with-button" onClick={handleOpenWithQGIS}><span>Ouvrir avec QGIS</span><img src='../img/qgis.svg' className="open-with-button-img" /></button>}
            </div>
            <div className="metadata-quality-container">
              <MetadataQuality quality={qualityValue} fields={fields} />
              <span className="data-card-progress-date">{getRelativeDate(data.date_derniere_modif)}</span>
            </div>
          </div>
        </div>
  
        {data.thematique && (
          <div className="section">
            <h2 className="section-title">Informations internes</h2>
            <div className="section-content">
              {data.thematique && <div className="field"><span>Thématique associée :</span> <strong>{data.thematique}</strong></div>}
              {Array.isArray(data.observatoire) && data.observatoire.length > 0 && (
                <div className="field">
                  <span>Observatoire(s) associé(s) :</span>
                  <strong>
                    <div className="tags-inline">
                      {data.observatoire.map((obs: string, index: number) => (
                        <span className="tag" key={index}>{obs}</span>
                      ))}
                    </div>
                  </strong>
                </div>
              )}
              {data.etat && <div className="field"><span>État des données :</span> <strong>{data.etat}</strong></div>}
              {Array.isArray(data.etudes_publiees) && data.etudes_publiees.length > 0 && (
                <div className="field">
                  <ul>
                    {pageTitles.etudes_publiees.map((etude: EtudePubliee, index: number) => (
                      <li key={index} style={{ marginBottom: '5px' }}>
                          <a href={etude.url} target="_blank" rel="noopener noreferrer">
                            <strong>{etude.title}</strong>
                          </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
  
        {(data.emprise_spatiale || (Array.isArray(data.autres_emprises) && data.autres_emprises.length > 0) || data.type_geo || data.echelle_min || data.systeme_coord) && (
          <div className="section">
            <h2 className="section-title">Informations spatiales</h2>
            <div className="section-content">
              {data.emprise_spatiale && (
                <div className="field">
                  <span>Emprise spatiale :</span> <strong>{data.emprise_spatiale}</strong>
                </div>
              )}
              {Array.isArray(data.autres_emprises) && data.autres_emprises.length > 0 && (
                <div className="field">
                  <span>Autres emprises disponibles :</span>
                  <strong>
                    <div className="tags-inline">
                      {data.autres_emprises.map((emprise: string) => (
                        <Link key={emprise} to={`/${emprise}`} className="tag">{emprise}</Link>
                      ))}
                    </div>
                  </strong>
                </div>
              )}
              {data.echelle_min && (
                <div className="field">
                  <span>Niveau de détail le plus fin :</span> <strong>{data.echelle_min}</strong>
                </div>
              )}
              {data.type_geo && (
                <div className="field">
                  <span>Type de représentation géographique :</span> <strong>{data.type_geo}</strong>
                </div>
              )}
              {data.systeme_coord && (
                <div className="field">
                  <span>Système de coordonnées :</span> <strong>{data.systeme_coord}</strong>
                </div>
              )}
              {data.type_geo && (
                <MapPreview nom_ressource={nomRessource || 'defaultResourceName'} /> // affiche un aperçu de la carte si le type géographique est renseigné
              )}
            </div>
          </div>
        )}
  
        {(data.date_first_maj || data.date_last_maj || (Array.isArray(data.millesimes) && data.millesimes.length > 0) || data.freq_maj || data.freq_maj_possible || data.date_prochaine_maj) && (
          <div className="section">
            <h2 className="section-title">Informations temporelles</h2>
            <div className="section-content">
              {data.date_first_maj && <div className="field"><span>Date de création :</span> <strong>{formatDate(data.date_first_maj)}</strong></div>}
              {data.date_last_maj && <div className="field"><span>Date de dernière mise à jour :</span> <strong>{formatDate(data.date_last_maj)}</strong></div>}
              {Array.isArray(data.millesimes) && data.millesimes.length > 0 && (
                <div className="field">
                  <span>Millésime(s) disponibles :</span>
                  <strong>
                    <div className="tags-inline">
                      {data.millesimes.map((millesime: string, index: number) => (
                        <span className="tag" key={index}>{millesime}</span>
                      ))}
                    </div>
                  </strong>
                </div>
              )}
              {data.freq_maj && <div className="field"><span>Fréquence de mise à jour (AudéLor) :</span> <strong>{data.freq_maj}</strong></div>}
              {data.freq_maj_possible && <div className="field"><span>Fréquence de mise à jour (Fournisseur) :</span> <strong>{data.freq_maj_possible}</strong></div>}
              {data.date_prochaine_maj && <div className="field"><span>Date de prochaine mise à jour :</span> <strong>{formatDate(data.date_prochaine_maj)}</strong></div>}
            </div>
          </div>
        )}
  
        {(data.nom_fournisseur || data.mail_fournisseur || data.site_fournisseur || data.tel_fournisseur || data.licence_existante) && (
          <div className="split-section">
            <div className="section contact">
              <h2 className="section-title">Fournisseur</h2>
              <div className="section-content">
                {data.nom_fournisseur && <div className="field"><span>Nom du fournisseur :</span> <strong>{data.nom_fournisseur}</strong></div>}
                {data.mail_fournisseur && <div className="field"><span>Email du fournisseur :</span> <a href={`mailto:${data.mail_fournisseur}`}><strong>{data.mail_fournisseur}</strong></a></div>}
                {data.site_fournisseur && <div className="field"><span>Site du fournisseur :</span> <a href={data.site_fournisseur} target="_blank"><strong>{pageTitles.site_fournisseur}</strong></a></div>}
                {data.tel_fournisseur && <div className="field"><span>Téléphone du fournisseur :</span> <strong>{formatPhoneNumber(data.tel_fournisseur)}</strong></div>}
                {data.licence_existante && <div className="field"><span>Licence ou convention existante :</span> <strong>{convertLicenceValueToText(data.licence_existante)}</strong></div>}
              </div>
            </div>
            <div className="section contact">
              <h2 className="section-title">Producteur</h2>
              <div className="section-content">
                {data.nom_producteur && <div className="field"><span>Nom du producteur :</span> <strong>{data.nom_producteur}</strong></div>}
                {data.mail_producteur && <div className="field"><span>Email du producteur :</span> <a href={`mailto:{data.mail_producteur}`}><strong>{data.mail_producteur}</strong></a></div>}
                {data.site_producteur && <div className="field"><span>Site du producteur :</span> <a href={data.site_producteur} target="_blank"><strong>{pageTitles.site_producteur}</strong></a></div>}
                {data.tel_producteur && <div className="field"><span>Téléphone du producteur :</span> <strong>{formatPhoneNumber(data.tel_producteur)}</strong></div>}
                {data.lien_donnees_brut && <div className="field"><span>Lien vers la donnée brute :</span> <a href={data.lien_donnees_brut} target="_blank"><strong>{pageTitles.lien_donnees_brut}</strong></a></div>}
              </div>
            </div>
          </div>
        )}
  
        {(data.charges_etudes && data.charges_etudes.length > 0) && (
          <div className="section">
            <h2 className="section-title">Référent(s)</h2>
            <div className="section-content">
              {data.charges_etudes.map((ce: any, index: number) => (
                <div key={ce.id}>
                  <div className="field"><span>Nom du/de la chargé·e d'études :</span> <strong>{ce.nom_ce}</strong></div>
                  <div className="field"><span>Email du/de la chargé·e d'études :</span> <a href={`mailto:{ce.mail_ce}`}><strong>{ce.mail_ce}</strong></a></div>
                  <div className="field"><span>Téléphone du/de la chargé·e d'études :</span> <strong>{formatPhoneNumber(ce.tel_ce)}</strong></div>
                  {index < data.charges_etudes.length - 1 && <hr style={{ marginTop: '15px', marginBottom: '15px' }} />}
                </div>
              ))}
            </div>
          </div>
        )}
  
        <div className="section">
          <h2 className="section-title">Aperçu de la table</h2>
          <div className="section-content table-preview">
            {data.lien_dictionnaire && (
              <div className="field"><span>Lien vers un dictionnaire :</span> <a href={data.lien_dictionnaire} target="_blank"> <strong>{pageTitles.lien_dictionnaire || 'Non renseigné'}</strong></a></div>
            )}
            {tablePreview.length > 0 ? (
              <div className="table-container">
                <table className="data-table">
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tablePreview.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <td key={colIndex}>{row[column]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>Aucune donnée disponible</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSheet;
