import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, CircularProgress, FormControlLabel, Switch, Tooltip, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from '@mui/icons-material';
import axios from 'axios';
import DataCard from './DataCard';
import DataCardDatagences from './DataCardDatagences';
import MultiSelectFilter from './MultiSelectFilter';
import ThematicButton from './ThematicButton';
import TopBar from './TopBar';
import './css/Home.css';

// Variables d'environnement pour la pagination et les URLs de l'API
const ITEMS_PER_PAGE = 10;
const API_URL = process.env.REACT_APP_API_URL;


/**
 * Composant représentant la page d'accueil et de recherche.
 * @return Le composant Home.
 */
const Home: React.FC = () => {
  // États pour gérer les différents aspects de la page
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]); // thèmes sélectionnés
  const [data, setData] = useState<any[]>([]); // données récupérées de l'API
  const [showUncatalogued, setShowUncatalogued] = useState<boolean>(false); // affichage des données non cataloguées
  const [searchValue, setSearchValue] = useState<string>(''); // valeur de recherche
  const [filteredData, setFilteredData] = useState<any[]>([]); // données filtrées selon les critères de recherche
  const [sortOption, setSortOption] = useState<string>('date-recent'); // option de tri sélectionnée
  const [isLoading, setIsLoading] = useState<boolean>(true); // état de chargement
  const [currentPage, setCurrentPage] = useState<number>(1); // page actuelle pour la pagination

  // États pour les filtres supplémentaires
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]); // mots-clés sélectionnés
  const [selectedFournisseurs, setSelectedFournisseurs] = useState<string[]>([]); // fournisseurs sélectionnés
  const [selectedProducteurs, setSelectedProducteurs] = useState<string[]>([]); // producteurs sélectionnés
  const [selectedEmprises, setSelectedEmprises] = useState<string[]>([]); // emprises spatiales sélectionnées
  const [selectedObservatoires, setSelectedObservatoires] = useState<string[]>([]); // observatoires sélectionnés

  const navigate = useNavigate(); // hook pour la navigation

  // Effet pour récupérer les données de l'API lors du chargement initial
  useEffect(() => {
    /**
     * Récupération des données de toutes les thématiques.
     */
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/getAllThemes`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setData(response.data); // enregistre les données récupérées dans l'état
      } catch (error) {
        console.error('Error fetching data:', error); // gère les erreurs
      } finally {
        setIsLoading(false); // désactive l'état de chargement
      }
    };

    fetchData(); // appelle la fonction de récupération des données
  }, []); // tableau de dépendances vide pour n'exécuter cet effet qu'une fois


  /**
   * Filtrer les données en fonction des critères de recherche.
   */
  useEffect(() => {
    let filtered = data;

    // Filtre par thème sélectionné
    if (selectedThemes.length > 0) {
      if (selectedThemes.includes('Datagences')) {
        filtered = filtered.filter(item =>
          selectedThemes.includes(item.thematique) || item.nom_table?.startsWith('f_')
        );
      } else {
        filtered = filtered.filter(item =>
          selectedThemes.includes(item.thematique)
        );
      }
    }

    // Filtre par qualité (cataloguées/non cataloguées)
    filtered = filtered.filter(item => {
      return showUncatalogued ? item.qualite == 0 : item.qualite > 0;
    });

    // Filtre par mots-clés, fournisseurs, producteurs, emprises et observatoires
    if (selectedKeywords.length > 0) {
      filtered = filtered.filter(item => 
        item.mots_cles && item.mots_cles.some((keyword: string) => selectedKeywords.includes(keyword))
      );
    }

    if (selectedFournisseurs.length > 0) {
      filtered = filtered.filter(item => 
        item.nom_fournisseur && selectedFournisseurs.includes(item.nom_fournisseur)
      );
    }

    if (selectedProducteurs.length > 0) {
      filtered = filtered.filter(item => 
        item.nom_producteur && selectedProducteurs.includes(item.nom_producteur)
      );
    }

    if (selectedEmprises.length > 0) {
      filtered = filtered.filter(item => 
        item.emprise_spatiale && selectedEmprises.includes(item.emprise_spatiale)
      );
    }

    if (selectedObservatoires.length > 0) {
      filtered = filtered.filter(item => 
        item.observatoire && selectedObservatoires.includes(item.observatoire)
      );
    }

    // Filtre par valeur de recherche (texte dans divers champs)
    setFilteredData(
      filtered.filter(item =>
        (item.nom_ressource?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.nom_cube?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.titre?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.thematique?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.date_last_maj?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.date_maj?.toLowerCase().includes(searchValue.toLowerCase()) ||
        (item.mots_cles && item.mots_cles.join(', ').toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.observatoire && item.observatoire.join(', ').toLowerCase().includes(searchValue.toLowerCase())))
      )
    );
  }, [data, selectedThemes, showUncatalogued, searchValue, selectedKeywords, selectedFournisseurs, selectedProducteurs, selectedEmprises, selectedObservatoires]); // déclenchement lorsque ces états changent


  /**
   * Gère la sélection d'un thème de données.
   * @param theme - Le thème sélectionné.
   */
  const handleThemeToggle = (theme: string) => {
    setSelectedThemes(prevSelected =>
      prevSelected.includes(theme)
        ? prevSelected.filter(item => item !== theme) // retire le thème s'il est déjà sélectionné
        : [...prevSelected, theme] // ajoute le thème s'il n'est pas déjà sélectionné
    );
    setCurrentPage(1); // réinitialise la pagination à la première page
  };


  /**
   * Gère le changement de la valeur de recherche.
   * @param event - L'événement de changement de valeur.
   */
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value); // met à jour la valeur de recherche
  };


  /**
   * Gère le changement d'état du bouton d'affichage des données non cataloguées.
   */
  const handleUncataloguedToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUncatalogued(event.target.checked); // met à jour l'état d'affichage des données non cataloguées
  };


  /**
   * Gère le changement d'une option de tri.
   * @param sortOption - L'option de tri sélectionnée.
   */
  const handleSortChange = (sortOption: string) => {
    setSortOption(sortOption); // met à jour l'option de tri sélectionnée
  };

  /**
   * Récupère tous les mots-clés existants.
   * @return Un tableau des mots-clés existants.
   */
  const fetchKeywords = async () => {
    const response = await axios.get(`${API_URL}/getAllDistinctKeywords`, {
    });
    return response.data.map((item: any) => item.mot_cle); // retourne un tableau des mots-clés
  };


  /**
   * Récupère tous les fournisseurs.
   * @return Un tableau des noms de fournisseurs.
   */
  const fetchFournisseurs = async () => {
    const response = await axios.get(`${API_URL}/getAllFournisseurs`, {
    });
    return response.data.map((item: any) => item.nom_contact); // retourne un tableau des noms de fournisseurs
  };


  /**
   * Récupère tous les producteurs.
   * @return Un tableau des noms de producteurs.
   */
  const fetchProducteurs = async () => {
    const response = await axios.get(`${API_URL}/getAllProducteurs`, {
    });
    return response.data.map((item: any) => item.nom_contact); // retourne un tableau des noms de producteurs
  };


  /**
   * Récupère toutes les emprises spatiales.
   * @return Un tableau des emprises spatiales.
   */
  const fetchEmprises = async () => {
    const response = await axios.get(`${API_URL}/getAllEmprises`, {
    });
    return response.data.map((item: any) => item.libelle); // retourne un tableau des emprises spatiales
  };


  /**
   * Récupère tous les observatoires.
   * @returns Un tableau des noms d'observatoires.
   */
  const fetchObservatoires = async () => {
    const response = await axios.get(`${API_URL}/getAllObservatoires`, {
    });
    return response.data.map((item: any) => item.libelle); // retourne un tableau des noms d'observatoires
  };


  /**
   * Filtrer les données en fonction des critères de recherche.
   */
  useEffect(() => {
    let sortedData = [...data];
    
    // Tri des données selon l'option sélectionnée
    switch (sortOption) {
      case 'qualite-high':
        sortedData = sortedData.sort((a, b) => b.qualite - a.qualite); // tri par qualité décroissante
        break;
      case 'qualite-low':
        sortedData = sortedData.sort((a, b) => a.qualite - b.qualite); // tri par qualité croissante
        break;
      case 'date-recent':
        sortedData = sortedData.sort((a, b) => new Date(b.date_last_maj).getTime() - new Date(a.date_last_maj).getTime()); // tri par date récente
        break;
      case 'date-old':
        sortedData = sortedData.sort((a, b) => new Date(a.date_last_maj).getTime() - new Date(b.date_last_maj).getTime()); // tri par date ancienne
        break;
      default:
        break;
    }
  
    // Application des filtres sélectionnés et de la valeur de recherche
    setFilteredData(
      sortedData.filter(item =>
        (selectedThemes.length === 0 || selectedThemes.includes(item.thematique) || (selectedThemes.includes('Datagences') && item.nom_table?.startsWith('f_'))) &&
        (showUncatalogued ? item.qualite <= 6 : item.qualite > 6) &&
        (
          item.nom_ressource?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.nom_cube?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.titre?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.thematique?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.date_last_maj?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.date_maj?.toLowerCase().includes(searchValue.toLowerCase()) ||
          (item.mots_cles && item.mots_cles.length > 0 && item.mots_cles.join(', ').toLowerCase().includes(searchValue.toLowerCase())) ||
          (item.observatoire && item.observatoire.length > 0 && item.observatoire.join(', ').toLowerCase().includes(searchValue.toLowerCase()))
        )
      )
    );
  }, [searchValue, data, selectedThemes, showUncatalogued, sortOption]); // déclenchement lorsque ces états changent

  
  /**
   * Détermine le nombre de colonnes à afficher en fonction de la largeur de la fenêtre.
   * @return Le nombre de colonnes.
   */
  const getGridColumns = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1200) return 3;
    if (windowWidth >= 768) return 2;
    return 1;
  };


  /**
   * Rendu de la grille des résultats.
   * @returns Le composant de la grille des résultats.
   */
  const renderGrid = () => {
    const columns = getGridColumns();
    const cardWidth = 550;
    const spacing = 20;
    const containerWidth = columns * (cardWidth + spacing);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE; // index de départ pour la pagination
    const paginatedData = filteredData.slice(startIndex, startIndex + ITEMS_PER_PAGE); // extrait les données pour la page actuelle

    return (
      <div style={{ display: 'flex', justifyContent: 'center', maxWidth: containerWidth, margin: '0 auto' }}>
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {paginatedData.map((item, index) => (
            <div key={`${item.nom_ressource || item.nom_cube}-${index}`} style={{ margin: spacing / 2 }}>
              {item.nom_table && item.nom_table.startsWith('f_') ? (
                <a
                  href="https://datagences-bretagne.bzh/catalogue-tableau/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <DataCardDatagences
                    nom_cube={item.nom_cube}
                    thematique={item.thematique}
                    date_maj={item.date_maj}
                    searchValue={searchValue}
                    qualite={"100"}
                    onClick={() => {}} // action vide pour ce type de carte
                  />
                </a>
              ) : (
                <DataCard
                  nom_ressource={item.nom_ressource}
                  titre={item.titre}
                  mots_cles={item.mots_cles}
                  description={item.description}
                  thematique={item.thematique}
                  observatoire={item.observatoire}
                  emprise_spatiale={item.emprise_spatiale}
                  echelle_min={item.echelle_min}
                  date_last_maj={item.date_last_maj}
                  millesimes={item.millesimes}
                  qualite={item.qualite}
                  date_derniere_modif={item.date_derniere_modif}
                  searchValue={searchValue}
                  onClick={() => navigate(`/${item.nom_ressource}`)} // Rediriger vers la page de vue détaillée
                  onEditClick={() => navigate(`/${item.nom_ressource}/edit`)} // Rediriger vers la page d'édition
                  nom_fournisseur={item.nom_fournisseur}
                  nom_producteur={item.nom_producteur}
                  nom_ce_aud={item.nom_ce_aud}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };


  /**
   * Rendu des boutons de changement de page.
   * @returns Le composant de pagination.
   */
  const renderPagination = () => {
    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE); // calcule le nombre total de pages
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
        <IconButton
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1} // désactive le bouton si on est sur la première page
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1} // désactive le bouton si on est sur la première page
        >
          <NavigateBefore />
        </IconButton>
        <span style={{ margin: '0 20px', alignSelf: 'center' }}>
          Page {currentPage} sur {totalPages} {/* affiche la page actuelle et le nombre total de pages */}
        </span>
        <IconButton
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages} // désactive le bouton si on est sur la dernière page
        >
          <NavigateNext />
        </IconButton>
        <IconButton
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages} // désactive le bouton si on est sur la dernière page
        >
          <LastPage />
        </IconButton>
      </div>
    );
  };

  
  return (
    <div>
      <TopBar context="Home" nomRessource='' />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ width: '100%', maxWidth: '1215px', margin: '0 auto' }}>
          <Grid container spacing={2} justifyContent="center" marginTop='100px' style={{ width: '100%', marginLeft: '-9px' }}>
            {/* Boutons pour sélectionner les thématiques */}
            <Grid item xs={2}>
              <ThematicButton
                title="SCoT du pays de Lorient, Centralités..."
                imgSrc="/img/territoires_amenagement.svg"
                onClick={() => handleThemeToggle('Territoires et aménagement')}
                selected={selectedThemes.includes('Territoires et aménagement')}
              />
            </Grid>
            <Grid item xs={2}>
              <ThematicButton
                title="Énergie, Agriculture, Eau..."
                imgSrc="/img/environnement.svg"
                onClick={() => handleThemeToggle('Environnement')}
                selected={selectedThemes.includes('Environnement')}
              />
            </Grid>
            <Grid item xs={2}>
              <ThematicButton
                title="Zones d'activités, Marchés fonciers sur le Pays de Lorient, Consommation d'espace..."
                imgSrc="/img/foncier.svg"
                onClick={() => handleThemeToggle('Foncier')}
                selected={selectedThemes.includes('Foncier')}
              />
            </Grid>
            <Grid item xs={2}>
              <ThematicButton
                title="Habitat et copropriétés, Mobilité, Équipements et infrastructures, Socio-démograhie..."
                imgSrc="/img/habitat_societe.svg"
                onClick={() => handleThemeToggle('Habitat et société')}
                selected={selectedThemes.includes('Habitat et société')}
              />
            </Grid>
            <Grid item xs={2}>
              <ThematicButton
                title="Commerce, Entreprises, Bureaux, Maritime, Tourisme, Emploi - Formation,, Numérique..."
                imgSrc="/img/economie.svg"
                onClick={() => handleThemeToggle('Économie')}
                selected={selectedThemes.includes('Économie')}
              />
            </Grid>
            <Grid item xs={2}>
              <ThematicButton
                title="Catalogue Datagences"
                imgSrc="/img/datagences.svg"
                onClick={() => handleThemeToggle('Datagences')}
                selected={selectedThemes.includes('Datagences')}
              />
            </Grid>
          </Grid>
        </div>
        {/* Champ de recherche */}
        <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', marginBottom: '25px' }}>
          <TextField
            id="search"
            label="Rechercher une donnée..."
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ width: '100%', marginTop: '50px' }}
          />
        </div>
      </div>
      {/* Filtre et options de tri */}
      <Grid container justifyContent="space-between" alignItems="center" style={{ width: '100%', maxWidth: '1200px', margin: '0 auto 20px auto' }}>
        {!showUncatalogued && (
          <div style={{ display: 'flex', gap: '10px', width: '1250px' }}>
            <Grid item xs={12} md={2.5}>
              <MultiSelectFilter
                label="Mots-clés"
                fetchData={fetchKeywords}
                onChange={setSelectedKeywords}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <MultiSelectFilter
                label="Fournisseurs"
                fetchData={fetchFournisseurs}
                onChange={setSelectedFournisseurs}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <MultiSelectFilter
                label="Producteurs"
                fetchData={fetchProducteurs}
                onChange={setSelectedProducteurs}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <MultiSelectFilter
                label="Emprises spatiales"
                fetchData={fetchEmprises}
                onChange={setSelectedEmprises}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <MultiSelectFilter
                label="Observatoires"
                fetchData={fetchObservatoires}
                onChange={setSelectedObservatoires}
              />
            </Grid>
          </div>
        )}
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', marginTop: '12.5px', marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-4px' }}>
              {filteredData.length > 0 ? `${filteredData.length} résultat${filteredData.length > 1 ? 's' : ''}` : 'Aucun résultat'}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontWeight: showUncatalogued ? 'normal' : 'bold' }}>Cataloguées</span>
              <Tooltip title="Afficher les données non cataloguées" arrow>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showUncatalogued}
                      onChange={handleUncataloguedToggle}
                      color="primary"
                    />
                  }
                  label=""
                  style={{ marginLeft: '1px', marginRight: '1px' }}
                />
              </Tooltip>
              <span style={{ fontWeight: showUncatalogued ? 'bold' : 'normal' }}>Non cataloguées</span>
            </div>
            {!showUncatalogued && (
              <div style={{ display: 'flex', gap: '10px', height: '32px' }}>
                <Button
                  variant={sortOption === 'date-recent' ? 'contained' : 'outlined'}
                  onClick={() => handleSortChange('date-recent')}
                  className={`sort-button ${sortOption === 'date-recent' ? 'active' : ''}`}
                >
                  Plus récentes
                </Button>
                <Button
                  variant={sortOption === 'date-old' ? 'contained' : 'outlined'}
                  onClick={() => handleSortChange('date-old')}
                  className={`sort-button ${sortOption === 'date-old' ? 'active' : ''}`}
                >
                  Moins récentes
                </Button>
                <Button
                  variant={sortOption === 'qualite-high' ? 'contained' : 'outlined'}
                  onClick={() => handleSortChange('qualite-high')}
                  className={`sort-button ${sortOption === 'qualite-high' ? 'active' : ''}`}
                >
                  Plus pertinentes
                </Button>
                <Button
                  variant={sortOption === 'qualite-low' ? 'contained' : 'outlined'}
                  onClick={() => handleSortChange('qualite-low')}
                  className={`sort-button ${sortOption === 'qualite-low' ? 'active' : ''}`}
                >
                  Moins pertinentes
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {/* Affichage des résultats ou indicateur de chargement */}
      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {renderGrid()}
          {renderPagination()}
        </>
      )}
      <iframe id="hiddenIframe" style={{ display: 'none' }}></iframe>
    </div>
  );
};

export default Home;
