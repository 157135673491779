import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/index.css';
import Home from './Home';
import DataSheet from './DataSheet';
import EditDataSheet from './EditDataSheet';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:nomRessource" element={<DataSheet />} />
      <Route path="/:nomRessource/edit" element={<EditDataSheet />} />
    </Routes>
  </Router>
);
