import React, { useState, useEffect } from 'react';
import { Select, MenuItem, CircularProgress, Checkbox, ListItemText, TextField, InputLabel, FormControl, OutlinedInput, Box, SelectChangeEvent } from '@mui/material';

/**
 * Interface représentant les propriétés du composant MultiSelectFilter.
 */
interface MultiSelectFilterProps {
  label: string;
  fetchData: () => Promise<string[]>;
  onChange: (selected: string[]) => void;
}

/**
 * Composant pour un filtre à sélection multiple avec recherche intégrée.
 * @param props - Les propriétés du composant, incluant le label, la fonction de récupération des données et le gestionnaire de changement.
 * @return Le composant MultiSelectFilter.
 */
const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({ label, fetchData, onChange }) => {
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // récupère les options de sélection à partir de l'API
    fetchData()
      .then(data => {
        setOptions(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [fetchData]);

  // gère le changement de sélection dans la liste
  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    setSelectedOptions(value);
    onChange(value);
  };

  // gère le changement de la valeur de recherche
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  // filtre les options en fonction de la valeur de recherche
  const filteredOptions = options.filter(option =>
    option && option.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <FormControl fullWidth variant="outlined" margin="dense">
      <InputLabel
        sx={{
          fontSize: '0.950rem',
          '&.Mui-focused': {
            marginTop: '0.5px'
          },
          marginTop: '-4px'
        }}
      >
        {label}
      </InputLabel>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleSelectChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{label}</em>;
          }
          return (selected as string[]).join(', ');
        }}
        style={{ width: '100%', fontSize: '0.875rem', height: '45px' }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300, // hauteur de la liste déroulante
              backgroundColor: 'white'
            },
          },
        }}
        onClose={() => setSearchValue('')} // effacer la valeur de recherche à la fermeture de la liste déroulante
      >
        <Box px={2} pt={1} pb={1} style={{ pointerEvents: 'none' }}>
          <TextField
            placeholder={`Rechercher ${label.toLowerCase()}...`}
            value={searchValue}
            onChange={handleSearchChange}
            fullWidth
            style={{ pointerEvents: 'auto' }}
            InputProps={{ style: { fontSize: '0.875rem', height: '45px' } }}
            onClick={(event) => {
              event.stopPropagation(); // empêche le déclenchement de la sélection
            }}
            onKeyDown={(event) => event.stopPropagation()} // empêche le déclenchement de la sélection
          />
        </Box>
        {isLoading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />
          </MenuItem>
        ) : (
          filteredOptions.map((option, index) => (
            <MenuItem key={index} value={option} style={{ fontSize: '0.875rem', height: '40px' }}>
              <Checkbox checked={selectedOptions.indexOf(option) > -1} size="small" />
              <ListItemText primary={option} style={{ marginLeft: '8px' }} />
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default MultiSelectFilter;
