import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MetadataQuality from './MetadataQuality';
import './css/DataCard.css';
import { formatDate, getRelativeDate } from './utils/functions';

const newBanner = '../img/new-banner.svg';

/**
 * Interface représentant les propriétés des données pour le composant DataCard.
 */
interface DataProps {
  nom_ressource: string;
  titre: string;
  mots_cles?: string[];
  description: string;
  thematique: string;
  observatoire?: string[];
  emprise_spatiale: string;
  echelle_min: string;
  date_last_maj: string;
  millesimes: string[];
  qualite: string;
  date_derniere_modif: string;
  nom_fournisseur: string;
  nom_producteur: string;
  nom_ce_aud: string[];
  searchValue?: string;
  onClick: () => void;
  onEditClick: () => void;
}

/**
 * Récupère l'image correspondant à l'emprise spatiale.
 * @param emprise_spatiale - L'emprise spatiale de la ressource.
 * @return Le chemin de l'image correspondant à l'emprise spatiale.
 */
const getImageSrc = (emprise_spatiale: string) => {
  switch (emprise_spatiale) {
    case 'France métropolitaine':
      return '../img/0_metropole.svg';
    case 'Grand Ouest':
      return '../img/1_grand_ouest.svg';
    case 'Bretagne':
      return '../img/2_bretagne.svg';
    case 'Département(s) breton(s)':
      return '../img/3_departement_bzh.svg';
    case 'Morbihan':
      return '../img/4_morbihan.svg';
    case 'Pays de Lorient - Quimperlé':
      return '../img/5_scot_lorient_quimperle.svg';
    case 'SCoT du Pays de Lorient':
      return '../img/6_scot_lorient.svg';
    case 'Quimperlé Communauté':
      return '../img/7_scot_epci_quimperle.svg';
    case 'Lorient Agglomération':
      return '../img/8_epci_lorient_agglo.svg';
    case 'Blavet Bellevue Océan Communauté':
      return '../img/9_epci_bbo.svg';
    default:
      return '';
  }
};

/**
 * Composant représentant une carte de données (un résultat).
 * @param props - Les propriétés du composant, incluant les données de la carte et les fonctions de gestion d'événements.
 * @return Le composant DataCard.
 */
const DataCard: React.FC<DataProps> = ({
  nom_ressource,
  titre,
  mots_cles,
  description,
  thematique,
  emprise_spatiale,
  echelle_min,
  date_last_maj,
  millesimes,
  qualite,
  date_derniere_modif,
  nom_fournisseur,
  nom_producteur,
  nom_ce_aud,
  searchValue = '',
  onClick,
  onEditClick
}) => {
  const [open, setOpen] = useState(false); // état pour gérer l'ouverture de la popup de confirmation

  // récupère l'image correspondant à l'emprise spatiale
  const imageSrc = getImageSrc(emprise_spatiale);

  // vérifie si les données sont nouvelles (mises à jour au cours des 15 derniers jours)
  const today = new Date();
  const dateDiff = Math.floor((today.getTime() - new Date(date_last_maj).getTime()) / (1000 * 60 * 60 * 24));
  const isNew = dateDiff <= 15;

  /**
   * Gère le clic sur la carte. Si la qualité est supérieure à 6, déclenche onClick, sinon emmène vers la création d'une nouvelle fiche.
   */
  const handleClick = () => {
    const qualityValue = parseInt(qualite, 10);
    if (qualityValue > 6) {
      onClick(); // appelle la fonction onClick si la qualité est supérieure à 6
    } else {
      setOpen(true); // ouvre la popup de confirmation si la qualité est faible
    }
  };

  /**
   * Gère la fermeture de la popup de confirmation.
   * @param confirm - Indique si l'action de confirmation a été acceptée.
   */
  const handleClose = (confirm: boolean) => {
    setOpen(false); // ferme la popup
    if (confirm) {
      onEditClick(); // appelle la fonction onEditClick si l'utilisateur a confirmé
    }
  };

  /**
   * Met en surbrillance le texte correspondant à la valeur de recherche.
   * @param text - Le texte à afficher.
   * @param highlight - Le texte à mettre en surbrillance.
   * @return Le texte avec les parties en surbrillance.
   */
  const highlightText = (text: string, highlight: string) => {
    if (typeof text !== 'string' || typeof highlight !== 'string') {
      return text; // retourne le texte tel quel si l'un des paramètres n'est pas une chaîne de caractères
    }

    const parts = text.split(new RegExp(`(${highlight})`, 'gi')); // sépare le texte selon la valeur de recherche
    return parts.map((part, index) => {
      if (part.toLowerCase() === highlight.toLowerCase()) {
        return <strong key={index} style={{ backgroundColor: 'orange' }}>{part}</strong>; // met en surbrillance la partie du texte qui correspond à la recherche
      } else {
        return part;
      }
    });
  };

  // calcule le critère de qualité de la carte
  const qualityValue = parseInt(qualite, 10);
  const isCatalogued = qualityValue > 0; // vérifie si la carte est cataloguée (qualité > 0)

  // vérifie si les champs du critère de qualité sont renseignés
  const fields = {
    'Titre': !!titre,
    'Mots-clés': !!mots_cles && mots_cles.length > 0,
    'Description': !!description,
    'Emprise spatiale': !!emprise_spatiale,
    'Niveau de détail le plus fin': !!echelle_min,
    'Date de dernière mise à jour': !!date_last_maj,
    'Millésimes': !!millesimes,
    'Fournisseur': !!nom_fournisseur,
    'Producteur': !!nom_producteur,
    'Référent(s)': !!nom_ce_aud 
  };

  return (
    <>
      <div onClick={handleClick} className="data-card fade-in">
        {isNew && <img src={newBanner} alt="New" className="new-banner" />} {/* Affiche une bannière "Nouveau" si les données ont été récemment mises à jour */}
        <h3 className="data-card-title">{titre ? highlightText(titre, searchValue) : highlightText(nom_ressource, searchValue)}</h3> {/* Titre de la carte */}
        <div>
          <div className="data-card-tags">
            {mots_cles && mots_cles.map((mot: string, index: number) => (
              <div key={index} className="data-card-tag">
                {highlightText(mot, searchValue)} {/* Affiche les mots-clés avec la mise en surbrillance */}
              </div>
            ))}
          </div>
        </div>
        <p className="data-card-description">{highlightText(description, searchValue)}</p> {/* Description de la carte */}
        <div className="data-card-info">
          {imageSrc && <img src={imageSrc} alt="Spatial coverage map" className="data-card-img" />} {/* Image correspondant à l'emprise spatiale */}
          <div>
            <span><strong>Thématique :</strong> {highlightText(thematique, searchValue)}</span><br />
            {isCatalogued && (
              <>
                <span><strong>Emprise spatiale :</strong> {highlightText(emprise_spatiale, searchValue)}</span><br />
                <span><strong>Dernière mise à jour :</strong> {formatDate(date_last_maj)}</span><br /><br />
              </>
            )}
            {isCatalogued && (
              <div className="data-card-progress-container">
                <MetadataQuality quality={qualityValue} fields={fields} /> {/* Composant affichant la qualité des métadonnées */}
                <span className="data-card-progress-date">{getRelativeDate(date_derniere_modif)}</span> {/* Date relative de la dernière modification */}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Popup de confirmation pour créer une nouvelle fiche */}
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle sx={{marginTop: '10px'}}><span><strong>{"Créer une nouvelle fiche ?"}</strong></span></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Voulez-vous créer une nouvelle fiche pour <strong>{nom_ressource}</strong></span> ?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} sx={{ color: 'black' }} color="primary">
            Annuler
          </Button>
          <Button onClick={() => handleClose(true)} sx={{ color: 'black' }} color="primary" autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DataCard;
