import React from 'react';
import { Button, Tooltip } from '@mui/material';
import './css/ThematicButton.css';

/**
 * Interface représentant les propriétés du composant ThematicButton.
 */
interface ThematicButtonProps {
  title: string;
  imgSrc: string;
  onClick: () => void;
  disabled?: boolean; 
  selected?: boolean;
}

/**
 * Composant pour afficher un bouton thématique avec une image et une infobulle.
 * @param props - Les propriétés du composant, incluant le titre, la source de l'image et le gestionnaire de clic.
 * @return Le composant ThematicButton.
 */
const ThematicButton: React.FC<ThematicButtonProps> = ({ title, imgSrc, onClick, disabled, selected }) => {
  return (
    <Tooltip
      title={disabled ? 'Bientôt disponible' : title}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '500px',
          },
        },
      }}
    >
      <span style={{ width: '100%', display: 'inline-block' }}>
        <Button
          onClick={onClick}
          variant="outlined"
          fullWidth
          disabled={disabled}
          className="theme-button"
          sx={{
            width: '100%',
            padding: '10px',
            border: 'none',
            borderRadius: '10px',
            boxShadow: selected ? '0 0 10px rgba(0, 123, 255, 0.5)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
            backgroundColor: selected ? '#e3f2fd' : 'transparent',
            '&:hover': {
              transform: disabled ? 'none' : 'scale(1.05)',
              boxShadow: disabled ? 'none' : selected ? '0 0 15px rgba(0, 123, 255, 0.7)' : '0 8px 16px rgba(0, 0, 0, 0.2)',
              backgroundColor: selected ? '#e3f2fd' : 'transparent',
            },
            '&.MuiButton-outlined': {
              border: 'none',
            },
          }}
        >
          <img src={imgSrc} alt={title} style={{ opacity: disabled ? 0.5 : 1 }} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default ThematicButton;
