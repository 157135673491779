/**
 * Formatte une date au format français.
 * @param dateString - La chaîne de caractères représentant la date à formatter.
 * @return La date formatée en français.
 */
export const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };
  
  /**
   * Récupère une description relative de la date par rapport à aujourd'hui.
   * @param dateString - La chaîne de caractères représentant la date.
   * @returns Une chaîne de caractères décrivant la date relative (aujourd'hui, hier, ou date spécifique).
   */
  export const getRelativeDate = (dateString: string): string => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const daysDifference = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
  
    if (daysDifference === 0) {
      return "Mis à jour aujourd'hui";
    } else if (daysDifference === 1) {
      return "Mis à jour hier";
    } else {
      return "Mis à jour le " + formatDate(dateString);
    }
  };
  
  /**
   * Formatte un numéro de téléphone avec des espaces.
   * @param phoneNumber - Le numéro de téléphone à formater.
   * @return Le numéro de téléphone formaté avec des espaces.
   */
  export const formatPhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber) return '';
    return phoneNumber.replace(/(\d{2})(?=\d)/g, '$1 ');
  };
  
  /**
   * Convertit une valeur du champ "Licence existante" en texte.
   * @param value - La valeur du champ.
   * @return Le texte correspondant à la valeur du champ.
   */
  export const convertLicenceValueToText = (value: boolean | null): string | null => {
    if (value === true) return 'Oui';
    if (value === false) return 'Non';
    return null;
  };
  
  /**
   * Formatte un nombre avec des espaces comme séparateurs de milliers.
   * @param num - Le nombre à formater.
   * @return Le nombre formaté avec des espaces.
   */
  export const formatNumber = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };