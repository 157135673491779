import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, CircleMarker, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import L from 'leaflet';

// définit une icône par défaut pour les marqueurs
const DefaultIcon = L.icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

// applique l'icône par défaut à tous les marqueurs Leaflet
L.Marker.prototype.options.icon = DefaultIcon;

const API_URL = process.env.REACT_APP_API_URL;

/**
 * interface pour les propriétés du composant MapPreview.
 */
interface MapPreviewProps {
  nom_ressource: string; // nom de la ressource géospatiale à afficher
}

/**
 * composant MapPreview pour afficher une carte avec des données géospatiales.
 * @param props - les propriétés du composant, y compris le nom de la ressource.
 */
const MapPreview: React.FC<MapPreviewProps> = ({ nom_ressource }) => {
  const [geoData, setGeoData] = useState<any[]>([]); // état pour stocker les données géospatiales
  const [loading, setLoading] = useState<boolean>(true); // indicateur de chargement
  const [error, setError] = useState<string | null>(null); // état pour stocker les erreurs éventuelles
  const [offset, setOffset] = useState<number>(0); // offset pour la pagination des données
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false); // indique si toutes les données ont été chargées
  const limit = 1500; // limite de résultats par requête
  const maxFeatures = 30000; // nombre maximum de caractéristiques à charger
  const cooldown = 3000; // temps d'attente entre les requêtes

  useEffect(() => {
    // fonction pour récupérer les données géospatiales de l'API
    const fetchData = async () => {
      // vérifie si le nombre maximum de caractéristiques est atteint
      if (geoData.length >= maxFeatures) {
        setAllDataLoaded(true); // marque toutes les données comme chargées
        return;
      }

      setLoading(true); // début du chargement
      setError(null); // réinitialise les erreurs

      try {
        // requête à l'API pour récupérer les données géospatiales
        const response = await axios.get(`${API_URL}/getGeoData/${nom_ressource}`, {
          params: { limit, offset }
        });

        // vérifie si la réponse contient des données géospatiales
        if (response.data && response.data.features) {
          console.log('API Response:', response.data);
          // si le nombre de résultats est inférieur à la limite, toutes les données sont chargées
          if (response.data.features.length < limit) {
            setAllDataLoaded(true);
          }

          // ajoute les nouvelles données aux données déjà chargées
          setGeoData(prevData => [...prevData, ...response.data.features]);
        } else {
          setError("Les données géospatiales sont manquantes ou invalides.");
        }
      } catch (error) {
        // gère les erreurs de requête
        console.error("La prévisualisation cartographique n'a pas pu être chargée :", error);
        setError("La prévisualisation cartographique n'a pas pu être chargée :");
      } finally {
        setLoading(false); // fin du chargement
      }
    };

    // si toutes les données ne sont pas chargées, continue de les charger périodiquement
    if (!allDataLoaded) {
      const timer = setTimeout(() => {
        fetchData(); // appelle la fonction pour charger plus de données
        setOffset(prevOffset => prevOffset + limit); // augmente l'offset pour la pagination
      }, cooldown);
      
      return () => clearTimeout(timer);
    }
  }, [nom_ressource, offset, allDataLoaded, geoData]);

  const polygonStyle = {
    color: '#0056b3',
    weight: 2,
    fillOpacity: 0.2,
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px' }}>
      {error ? (
        <div style={{ color: 'red', textAlign: 'center', padding: '10px', backgroundColor: '#ffe6e6' }}>
          {error}
        </div>
      ) : (
        <MapContainer style={{ height: '100%', width: '100%' }} center={[47.735456, -3.365765]} zoom={8}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {geoData && geoData.map((feature: any, index: number) => {
            if (feature && feature.geometry && feature.geometry.type) {
              if (feature.geometry.type === "Point") {
                const [lng, lat] = feature.geometry.coordinates;
                return (
                  <CircleMarker
                    key={index}
                    center={[lat, lng]}
                    radius={5}
                    color="black"
                    fillColor="blue"
                    fillOpacity={0.5}
                    weight={1}
                  />
                );
              } else if (feature.geometry.type === "MultiPoint") {
                return feature.geometry.coordinates.map((coord: [number, number], subIndex: number) => (
                  <CircleMarker
                    key={`${index}-${subIndex}`}
                    center={[coord[1], coord[0]]}
                    radius={5}
                    color="black"
                    fillColor="blue"
                    fillOpacity={0.5}
                    weight={1}
                  />
                ));
              } else if (feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon") {
                return (
                  <GeoJSON key={index} data={feature} style={polygonStyle} />
                );
              }
            }
            return null;
          })}
          {loading && (
            <div style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '5px' }}>
              Chargement...
            </div>
          )}
        </MapContainer>
      )}
    </div>
  );
};

export default MapPreview;
