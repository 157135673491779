import React, { useState, useRef, useEffect } from 'react';
import './css/MetadataQuality.css';

/**
 * Interface représentant les propriétés du composant MetadataQuality.
 */
interface MetadataQualityProps {
  quality: number;
  fields: { [key: string]: boolean };
}

/**
 * Obtient la couleur de la barre de progression en fonction de la qualité.
 * @param quality - Le pourcentage de qualité.
 * @return La couleur de la barre de progression.
 */
const getProgressBarColor = (quality: number) => {
  if (quality >= 80) {
    return 'green';
  } else if (quality >= 50) {
    return 'orange';
  } else {
    return 'red';
  }
};

/**
 * Composant pour afficher la qualité des métadonnées avec une barre de progression.
 * @param props - Les propriétés du composant, incluant le pourcentage de qualité et les champs associés.
 * @return Le composant MetadataQuality.
 */
const MetadataQuality: React.FC<MetadataQualityProps> = ({ quality, fields }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [position, setPosition] = useState<'above' | 'below'>('below');
  const infoIconRef = useRef<HTMLImageElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showDetails && infoIconRef.current && detailsRef.current) {
      const iconRect = infoIconRef.current.getBoundingClientRect();
      const detailsRect = detailsRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (iconRect.bottom + detailsRect.height > windowHeight) {
        setPosition('above');
      } else {
        setPosition('below');
      }
    }
  }, [showDetails]);

  // affiche les détails des champs lorsque la souris entre sur l'icône d'information
  const handleMouseEnter = () => {
    setShowDetails(true);
  };

  // cache les détails des champs lorsque la souris quitte l'icône d'information
  const handleMouseLeave = () => {
    setShowDetails(false);
  };

  return (
    <div className="metadata-quality-container">
      <div className="metadata-quality">
        <img
          src="../img/i_qualite.svg"
          alt="Info"
          className="info-icon"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={infoIconRef}
        />
        <div className="metadata-quality-bar">
          <div className="metadata-quality-fill" style={{ width: `${quality}%`, backgroundColor: getProgressBarColor(quality) }}></div>
          {/*<div className="metadata-quality-value">{quality}%</div>*/}
        </div>
      </div>
      {showDetails && (
        <div
          className={`metadata-quality-details ${position === 'above' ? 'above' : 'below'}`}
          ref={detailsRef}
        >
          {Object.keys(fields).map((field, index) => (
            <div key={index} className="metadata-quality-detail">
              {fields[field] ? <span className="valid">✔️</span> : <span className="invalid">❌</span>}
              <span>{field}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MetadataQuality;
