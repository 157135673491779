import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/TopBar.css';
import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

/**
 * Interface représentant les propriétés du composant TopBar.
 */
interface TopBarProps {
  context: 'Home' | 'DataSheet' | 'EditDataSheet';
  nomRessource: string | undefined;
  onSave?: () => void;
}

/**
 * Composant pour afficher une barre supérieure avec des boutons contextuels.
 * @param props - Les propriétés du composant, incluant le contexte, le nom de la ressource et le gestionnaire de sauvegarde.
 * @return Le composant TopBar.
 */
const TopBar: React.FC<TopBarProps> = ({ context, nomRessource, onSave }) => {
  const navigate = useNavigate();
  const [openBackDialog, setOpenBackDialog] = useState(false);

  /**
   * Gère le clic sur le bouton de retour.
   */
  const handleBackClick = () => {
    if (context === 'EditDataSheet') {
      setOpenBackDialog(true);
    } else {
      navigate('/');
    }
  };

  /**
   * Gère la fermeture du dialogue de confirmation pour quitter sans sauvegarder.
   * @param confirm - Indique si l'utilisateur a confirmé ou annulé l'action.
   */
  const handleDialogClose = (confirm: boolean) => {
    setOpenBackDialog(false);
    if (confirm) {
      navigate(`/${nomRessource}`);
    }
  };

  /**
   * Gère le clic sur le bouton d'édition.
   */
  const handleEditClick = () => {
    navigate(`/${nomRessource}/edit`);
  };

  /**
   * Gère le clic sur le bouton de sauvegarde.
   */
  const handleSaveClick = () => {
    if (onSave) {
      onSave();
    }
  };

  /**
   * Ouvre OneNote avec un lien spécifique.
   */
  const handleOpenWithOneNote = () => {
    const onenoteUrl = 'onenote:///Y:/Connaissances%20SIG/Catalogue%20de%20données.one';
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = onenoteUrl;
    document.body.appendChild(iframe);
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  return (
    <div className="top-bar">
      {(context === 'DataSheet' || context === 'EditDataSheet') && (
        <div className="header-buttons">
          <button className="header-button" onClick={handleBackClick}><span><b>Retour</b></span></button>
          {context === 'DataSheet' && (
            <button className="header-button" onClick={handleEditClick}><span><b>Éditer la fiche</b></span><EditIcon style={{ fontSize: '20px', marginBottom: '-4px', marginLeft: '5px' }} /></button>
          )}
          {context === 'EditDataSheet' && (
            <button className="header-button" onClick={handleSaveClick}><span><b>Enregistrer</b></span><SaveIcon style={{ fontSize: '20px', marginBottom: '-4px', marginLeft: '7px' }} /></button>
          )}
        </div>
      )}
      <div className="center-content">
        <span className="title-text">Catalogue de données d'</span>
        <a href="https://www.audelor.com" target="_blank" rel="noopener noreferrer">
          <img src="/img/audelor.svg" alt="AudéLor" className="audelor-logo" />
        </a>
      </div>
      <div className="onenote-buttons-row">
        <button className="onenote-button" onClick={handleOpenWithOneNote}>
          <img src='../img/onenote.svg' className="onenote-button-img" />
        </button>
      </div>
      {context === 'EditDataSheet' && (
        <Dialog
          open={openBackDialog}
          onClose={() => handleBackClick()}
        >
          <DialogTitle sx={{ marginTop: '10px' }}><span><strong>{"Vos données ne sont pas sauvegardées"}</strong></span></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span>Voulez-vous vraiment quitter ?</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDialogClose(false)} sx={{ color: 'black' }} color="primary">
              Annuler
            </Button>
            <Button onClick={() => handleDialogClose(true)} sx={{ color: 'black' }} color="primary" autoFocus>
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default TopBar;
