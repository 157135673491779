import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, TextField, Autocomplete, IconButton, Select, MenuItem, InputLabel, FormControl, FormHelperText, SelectChangeEvent, Dialog, DialogActions, DialogContent, DialogTitle, Button, OutlinedInput, Box, Checkbox } from '@mui/material';
import axios, { AxiosError } from 'axios';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import './css/EditDataSheet.css';
import TopBar from './TopBar';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MetadataQuality from './MetadataQuality';
import { formatPhoneNumber, convertLicenceValueToText } from './utils/functions';

dayjs.extend(utc);

const API_URL = process.env.REACT_APP_API_URL;

const emojiHelper = "💡 ";

/**
 * Composant de modification de fiche de données.
 * @return Le composant EditDataSheet.
 */
const EditDataSheet: React.FC = () => {
  // Extraction du nom de la ressource à partir des paramètres d'URL et navigation
  const { nomRessource } = useParams<{ nomRessource: string }>();
  const navigate = useNavigate();

  // États pour gérer les données de la ressource, la qualité des métadonnées, les états de chargement et d'erreur
  const [data, setData] = useState<any | null>({ mots_cles: [], etudes_publiees: [''], millesimes: [] });
  const [metadataQuality, setMetadataQuality] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // États pour les sélections dans les listes déroulantes (fournisseurs, producteurs, etc.)
  const [fournisseurs, setFournisseurs] = useState<any[]>([]);
  const [producteurs, setProducteurs] = useState<any[]>([]);
  const [ceAud, setCeAud] = useState<any[]>([]);
  const [selectedFournisseur, setSelectedFournisseur] = useState<string>('');
  const [selectedProducteur, setSelectedProducteur] = useState<string>('');
  const [selectedCeAud, setSelectedCeAud] = useState<string[]>([]);
  const [observatoires, setObservatoires] = useState<any[]>([]);
  const [frequence_maj_possible, setFrequenceMajPossible] = useState<any[]>([]);
  const [frequence_maj, setFrequenceMaj] = useState<any[]>([]);
  const [etats, setEtats] = useState<any[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [emprises, setEmprises] = useState<any[]>([]);
  const [echellemin, setEchelleMin] = useState<any[]>([]);
  const [views, setViews] = useState<any[]>([]);
  const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);

  // États pour la gestion des erreurs de validation des champs
  const initialValidationErrors = {
    titre: data.titre ? '' : 'Le champ titre est obligatoire',
    description: data.description ? '' : 'Le champ description est obligatoire',
    mots_cles: data.mots_cles && data.mots_cles.length > 0 && data.mots_cles[0] ? '' : 'Le champ mot-clé est obligatoire',
    etudes_publiees: '',
    millesimes: '',
    lien_dictionnaire: '',
    lien_donnees_brut: ''
  };
  const [validationErrors, setValidationErrors] = useState(initialValidationErrors);

  // États pour la gestion de la boîte de dialogue de création de contact
  const [openDialog, setOpenDialog] = useState(false);
  const [newContact, setNewContact] = useState({ nom_contact: '', mail_contact: '', tel_contact: '', site_contact: '', type_contact: '' });
  const [newContactType, setNewContactType] = useState<string>('');
  const [contactValidationErrors, setContactValidationErrors] = useState({ mail_contact: '', tel_contact: '', site_contact: '' });
  const [searchFournisseur, setSearchFournisseur] = useState<string>('');
  const [searchProducteur, setSearchProducteur] = useState<string>('');

  // Filtre les fournisseurs et producteurs en fonction de la recherche
  const filteredFournisseurs = fournisseurs.filter(fournisseur =>
    fournisseur.nom_contact.toLowerCase().includes(searchFournisseur.toLowerCase())
  );
  
  const filteredProducteurs = producteurs.filter(producteur =>
    producteur.nom_contact.toLowerCase().includes(searchProducteur.toLowerCase())
  );

  /**
   * Vérifie si des erreurs de validation sont présentes et met à jour l'état des erreurs.
   */
  useEffect(() => {
    setValidationErrors({
      ...validationErrors,
      titre: data.titre ? '' : 'Le champ titre est obligatoire',
      description: data.description ? '' : 'Le champ description est obligatoire',
      mots_cles: data.mots_cles && data.mots_cles.length > 0 && data.mots_cles[0] ? '' : 'Le champ mot-clé est obligatoire',
    });
  }, [data.titre, data.description, data.mots_cles]);
  

  /**
   * Récupère les données de la ressource en fonction du nom de la ressource dans l'URL.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/getDataFor/${nomRessource}`);
        const data = response.data;
        setData({
          ...data,
          etudes_publiees: Array.isArray(data.etudes_publiees) ? data.etudes_publiees : [],
          millesimes: Array.isArray(data.millesimes) ? data.millesimes : [],
          date_first_maj: data.date_first_maj ? dayjs.utc(data.date_first_maj).format('DD/MM/YYYY') : null,
          date_last_maj: data.date_last_maj ? dayjs.utc(data.date_last_maj).format('DD/MM/YYYY') : null,
          date_prochaine_maj: data.date_prochaine_maj ? dayjs.utc(data.date_prochaine_maj).format('DD/MM/YYYY') : null,
          date_derniere_modif: data.date_derniere_modif ? dayjs.utc(data.date_derniere_modif).format('DD/MM/YYYY') : null,
          licence_existante: data.licence_existante === true ? 'Oui' : data.licence_existante === false ? 'Non' : '',
        });
        setSelectedFournisseur(data.id_fournisseur ? data.id_fournisseur.toString() : '');
        setSelectedProducteur(data.id_producteur ? data.id_producteur.toString() : '');
        setSelectedCeAud(data.charges_etudes ? data.charges_etudes.map((ce: any) => ce.id.toString()) : []);
        setLoading(false);
      } catch (err) {
        const error = err as AxiosError;
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 404) {
          setError('La table demandée n\'existe pas.');
        } else {
          setError('Erreur lors de la récupération des données.');
        }
        setLoading(false);
      }
    };

    // Récupère les données nécessaires pour les listes déroulantes (fournisseurs, producteurs, emprises, etc.)
    const fetchAllData = async () => {
      try {
        const [fournisseursResponse, producteursResponse, emprisesResponse, echelleMinResponse, ceAudResponse, observatoiresResponse, freqMajPossibleResponse, freqMajResponse, etatsResponse] = await Promise.all([
          axios.get(`${API_URL}/getAllFournisseurs`),
          axios.get(`${API_URL}/getAllProducteurs`),
          axios.get(`${API_URL}/getAllEmprises`),
          axios.get(`${API_URL}/getAllEchelleMin`),
          axios.get(`${API_URL}/getAllCeAud`),
          axios.get(`${API_URL}/getAllObservatoires`),
          axios.get(`${API_URL}/getAllFrequenceMajPossible`),
          axios.get(`${API_URL}/getAllFrequenceMaj`),
          axios.get(`${API_URL}/getAllEtats`)
        ]);

        setFournisseurs(fournisseursResponse.data);
        setProducteurs(producteursResponse.data);
        setEmprises(emprisesResponse.data);
        setEchelleMin(echelleMinResponse.data);
        setCeAud(ceAudResponse.data);
        setObservatoires(observatoiresResponse.data);
        setFrequenceMajPossible(freqMajPossibleResponse.data);
        setFrequenceMaj(freqMajResponse.data);
        setEtats(etatsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    fetchAllData();
  }, [nomRessource]);


  /**
   * Récupère les vues dépendantes de la ressource.
   */
  useEffect(() => {
    const fetchViews = async () => {
      try {
        const response = await axios.get(`${API_URL}/getViewsFor/${nomRessource}`);
        setViews(response.data.map((view: { full_view_name: string }) => view.full_view_name));
      } catch (error) {
        console.error('Error fetching views:', error);
      }
    };

    if (nomRessource) {
      fetchViews();
    }
  }, [nomRessource]);


  /**
   * Récupère tous les mots-clés existants pour l'autocomplétion.
   */
  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await axios.get(`${API_URL}/getAllDistinctKeywords`);
        const keywordList = response.data.map((keywordObj: { mot_cle: string }) => keywordObj.mot_cle);
        setKeywords(keywordList);
      } catch (error) {
        console.error('Error fetching keywords:', error);
      }
    };

    fetchKeywords();
  }, []);


  /**
   * Vérifie si les données de la ressource ont été modifiées et met à jour l'état en conséquence.
   */
  useEffect(() => {
    if (!Array.isArray(data.mots_cles) || data.mots_cles.length === 0) {
      setData({ ...data, mots_cles: [''] });
    }
    if (!Array.isArray(data.etudes_publiees) || data.etudes_publiees.length === 0) {
      setData({ ...data, etudes_publiees: [''] });
    }
    if (!Array.isArray(data.millesimes) || data.millesimes.length === 0) {
      setData({ ...data, millesimes: [''] });
    }
  }, [data.mots_cles, data.etudes_publiees, data.millesimes]);


  /**
   * Affiche un message de chargement si le chargement prend trop de temps.
   */
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoadingMessage("Oups, la table est un peu lourde... Juste le temps d'aller faire un tour à la machine à café 😉");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [loading]);

  // vérifie les champs renseignés pour la qualité des métadonnées
  const fields = {
    'Titre': !!data.titre,
    'Mots-clés': Array.isArray(data.mots_cles) && data.mots_cles.some((motCle: any) => typeof motCle === 'string' && motCle.trim() !== ''),
    'Description': !!data.description,
    'Emprise spatiale': !!data.emprise_spatiale,
    'Niveau de détail le plus fin': !!data.echelle_min,
    'Date de dernière mise à jour': !!data.date_last_maj,
    'Millésimes': Array.isArray(data.millesimes) && data.millesimes.some((millesime: any) => {
      const millesimeStr = millesime.toString().trim();
      return millesimeStr !== '' && Number.isInteger(parseInt(millesimeStr, 10));
    }),
    'Fournisseur': !!selectedFournisseur,
    'Producteur': !!selectedProducteur,
    'Référent(s)': selectedCeAud && selectedCeAud.length > 0,
  };


  /**
   * Calcule et met à jour la qualité des métadonnées en fonction des champs remplis.
   */
  useEffect(() => {
    const calculateMetadataQuality = () => {
      let quality = 0;
      if (fields['Titre']) quality += 1;
      if (fields['Mots-clés']) quality += 1;
      if (fields['Description']) quality += 1;
      if (fields['Emprise spatiale']) quality += 1;
      if (fields['Niveau de détail le plus fin']) quality += 1;
      if (fields['Date de dernière mise à jour']) quality += 1;
      if (fields['Millésimes']) quality += 1;
      if (fields['Fournisseur']) quality += 1;
      if (fields['Producteur']) quality += 1;
      if (fields['Référent(s)']) quality += 1;
  
      setMetadataQuality((quality / 10) * 100); // Calcul de la qualité en pourcentage
    };

    calculateMetadataQuality();
  }, [data, selectedFournisseur, selectedProducteur, selectedCeAud]);


  /**
   * Gère le changement de valeur d'un champ de saisie.
   * @param e - Événement de changement.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? '' : `Le champ ${name} est obligatoire`,
    }));

    if (name === 'lien_dictionnaire' || name === 'lien_donnees_brut') {
      if (value && !validateWebsite(value)) {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          [name]: 'Lien invalide'
        }));
      } else {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          [name]: ''
        }));
      }
    }
  };

  /**
   * Gère le changement de valeur pour les champs qui sont des tableaux (par exemple les mots-clés, les millésimes, etc.).
   * @param name - Nom du champ.
   * @param index - Index de l'élément dans le tableau.
   * @param value - Nouvelle valeur de l'élément.
   */
  const handleArrayChange = (name: string, index: number, value: string) => {
    if (name === 'millesimes') {
      if (!isValidMillesime(value)) {
        return;
      }
    }

    const newArray = [...data[name]];
    newArray[index] = value;
    setData({ ...data, [name]: newArray });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newArray.some(item => !item) ? `Le champ ${name} contient des valeurs invalides` : '',
    }));

    if (name === 'etudes_publiees') {
      if (value && !validateWebsite(value)) {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          etudes_publiees: 'Lien invalide'
        }));
      } else {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          etudes_publiees: ''
        }));
      }
    }
  };

  /**
   * Gère le changement de valeur du champ fournisseur lors de la recherche.
   * @param e - Événement de changement.
   */
  const handleSearchFournisseurChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFournisseur(event.target.value);
  };
  
  /**
   * Gère le changement de valeur du champ producteur lors de la recherche.
   * @param e - Événement de changement.
   */
  const handleSearchProducteurChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProducteur(event.target.value);
  };

  /**
   * Vérifie si des erreurs de validation sont présentes.
   * @return Vrai si des erreurs de validation sont présentes, faux sinon.
   */
  const hasValidationErrors = () => {
    return (
      !data.titre ||
      !data.description ||
      (data.mots_cles.length === 0 || !data.mots_cles[0]) ||
      (data.etudes_publiees && data.etudes_publiees.some((etude: string) => etude && !validateWebsite(etude))) ||
      (data.millesimes.some((millesime: string) => millesime && !isValidMillesime(millesime))) ||
      (data.lien_dictionnaire && !validateWebsite(data.lien_dictionnaire)) ||
      (data.lien_donnees_brut && !validateWebsite(data.lien_donnees_brut))
    );
  };


  /**
   * Ajoute un nouveau champ dans un tableau (par exemple, un nouveau mot-clé, un nouveau millésime, etc.).
   * @param name - Nom du champ de tableau.
   */
  const addArrayField = (name: string) => {
    if (name === 'mots_cles') {
      if (!data.mots_cles) {
        data.mots_cles = [];
      }
      if (data.mots_cles.length < 5) {
        setData({ ...data, [name]: [...data[name], ''] });
      }
    } else if (name === 'millesimes') {
      if (!data.millesimes) {
        data.millesimes = [];
      }
      if (data.millesimes.length < 10) {
        setData({ ...data, [name]: [...data[name], ''] });
      }
    } else if (name === 'etudes_publiees') {
      if (!data.etudes_publiees) {
        data.millesimes = [];
      }
      if (data.etudes_publiees.length < 10) {
        setData({ ...data, [name]: [...data[name], ''] });
      }
    }
  };


  /**
   * Supprime un champ dans un tableau (par exemple, un mot-clé, un millésime, etc.).
   * @param name - Nom du champ de tableau.
   * @param index - Index de l'élément à supprimer.
   */
  const removeArrayField = (name: string, index: number) => {
    const newArray = [...data[name]];
    newArray.splice(index, 1);
    setData({ ...data, [name]: newArray });
  };


  /**
   * Gère le changement de date pour les champs de type date.
   * @param name - Nom du champ de date.
   * @param date - Nouvelle date sélectionnée.
   */
  const handleDateChange = (name: string, date: Dayjs | null) => {
    setData({ ...data, [name]: date ? dayjs(date).format('DD/MM/YYYY') : null });
  };


  /**
   * Gère le changement de valeur pour les champs de sélection (Select).
   * @param event - Événement de changement.
   */
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };


  /**
   * Nettoie les données en supprimant les valeurs vides ou nulles.
   * @param data - Les données à nettoyer.
   * @returns Les données nettoyées
   */
  const cleanData = (data: any) => {
    const cleanedData: any = {};

    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        const cleanedArray = data[key].map((item: any) => {
          if (typeof item === 'string') {
            return item.trim() === '' ? null : item;
          }
          return item;
        }).filter((item: any) => item !== null);

        cleanedData[key] = cleanedArray.length === 0 ? null : cleanedArray;
      } else if (typeof data[key] === 'string') {
        cleanedData[key] = data[key].trim() === '' ? null : data[key];
      } else {
        cleanedData[key] = data[key];
      }
    });

    return cleanedData;
  };


  /**
   * Sauvegarde les données modifiées après avoir vérifié qu'il n'y a pas d'erreurs de validation.
   */
  const handleSave = async () => {
    const validationErrors = {
      titre: data.titre ? '' : 'Le champ titre est obligatoire',
      description: data.description ? '' : 'Le champ description est obligatoire',
      mots_cles: data.mots_cles && data.mots_cles.length > 0 && data.mots_cles[0] ? '' : 'Le champ mot-clé est obligatoire',
      etudes_publiees: data.etudes_publiees && data.etudes_publiees.some((etude: string) => etude && !validateWebsite(etude)) ? 'Lien invalide' : '',
      millesimes: data.millesimes.some((millesime: string) => millesime && !isValidMillesime(millesime)) ? 'Millésime invalide' : '',
      lien_dictionnaire: data.lien_dictionnaire && !validateWebsite(data.lien_dictionnaire) ? 'Lien invalide' : '',
      lien_donnees_brut: data.lien_donnees_brut && !validateWebsite(data.lien_donnees_brut) ? 'Lien invalide' : ''
    };

    setValidationErrors(validationErrors);

    if (hasValidationErrors()) {
      return;
    }

    const cleanedData = cleanData(data);
    const updatedData = {
      ...cleanedData,
      etudes_publiees: Array.isArray(cleanedData.etudes_publiees) ? cleanedData.etudes_publiees : [],
      id_fournisseur: selectedFournisseur ? parseInt(selectedFournisseur, 10) : null,
      id_producteur: selectedProducteur ? parseInt(selectedProducteur, 10) : null,
      ids_ce_aud: selectedCeAud ? selectedCeAud.map(id => parseInt(id, 10)) : [],
      date_derniere_modif: new Date().toISOString(),
      licence_existante: convertLicenceValueToText(data.licence_existante),
    };

    try {
      const response = await axios.post(`${API_URL}/createOrEditDataset`, updatedData);
      console.log('Dataset saved:', response.data);
      navigate(`/${nomRessource}`);
    } catch (error) {
      console.error('Error saving dataset:', error);
    }
  };


  /**
   * Gère le focus sur le champ des mots-clés pour afficher les options d'autocomplétion.
   */
  const handleKeywordFocus = () => {
    setFilteredKeywords(keywords);
  };


  /**
   * Gère le changement de valeur des mots-clés et met à jour les mots-clés filtrés pour l'autocomplétion.
   * @param index - Index du mot-clé dans le tableau.
   * @param value - Nouvelle valeur du mot-clé.
   */
  const handleKeywordChange = (index: number, value: string) => {
    const validatedValue = validateAndTransformInput(value);

    const newKeywords = [...data.mots_cles];
    newKeywords[index] = validatedValue;
    setData({ ...data, mots_cles: newKeywords });

    if (validatedValue) {
      setFilteredKeywords(keywords.filter(keyword => keyword.includes(validatedValue)));
    } else {
      setFilteredKeywords(keywords);
    }
  };


  /**
   * Valide et transforme une valeur d'entrée pour garantir sa conformité avec les règles attendues.
   * @param input - Valeur d'entrée.
   * @return La valeur transformée.
   */
  const validateAndTransformInput = (input: string) => {
    return input.replace(/[^a-z0-9-]/gi, '').toLowerCase();
  };


  /**
   * Vérifie si un millésime est valide.
   * @param millesime - Valeur du millésime.
   * @return Vrai si le millésime est valide, sinon faux.
   */
  const isValidMillesime = (millesime: string) => {
    return /^\d{0,4}$/.test(millesime);
  };


  /**
   * Gère la sélection d'un mot-clé dans la liste d'autocomplétion.
   * @param index - Index du mot-clé dans le tableau.
   * @param keyword - Mot-clé sélectionné.
   */
  const handleKeywordSelect = (index: number, keyword: string) => {
    const validatedValue = validateAndTransformInput(keyword);

    const newKeywords = [...data.mots_cles];
    newKeywords[index] = validatedValue;
    setData({ ...data, mots_cles: newKeywords });
    setFilteredKeywords([]);
  };


  /**
   * Gère le changement de valeur du champ CE (Chargé d'études).
   * @param event - Événement de changement.
   */
  const handleCeAudChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    setSelectedCeAud(selectedIds);
    const selectedCeAudData = selectedIds.map(id => ceAud.find(ce => ce.id.toString() === id));
    setData({
      ...data,
      mail_ce_aud: selectedCeAudData.map(ce => ce?.mail_ce).join(', '),
      tel_ce_aud: selectedCeAudData.map(ce => ce?.tel_ce).join(', '),
    });
  };


  /**
   * Ouvre la boîte de dialogue de création de contact pour ajouter un nouveau contact.
   * @param type_contact - Type de contact (Fournisseur ou Producteur).
   */
  const handleDialogOpen = (type_contact: string) => {
    setNewContactType(type_contact);
    setNewContact({ nom_contact: '', mail_contact: '', tel_contact: '', site_contact: '', type_contact: '' });
    setContactValidationErrors({ mail_contact: '', tel_contact: '', site_contact: '' });
    setOpenDialog(true);
  };


  /**
   * Ferme la boîte de dialogue de création de contact.
   */
  const handleDialogClose = () => {
    setOpenDialog(false);
  };


  /**
   * Génère un fichier ODC (Office Data Connection) pour la ressource.
   */
  const handleOpenWithExcel = async () => {
    try {
      const response = await axios.get(`${API_URL}/generateODC/${nomRessource}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-office' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nomRessource}.odc`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading ODC file:', error);
      setError('Error downloading ODC file');
    }
  };


  /**
   * Génère un fichier QGS (QGIS Project File) pour la ressource.
   */
  const handleOpenWithQGIS = async () => {
    try {
      const response = await axios.get(`${API_URL}/generateQGS/${nomRessource}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/xml'
        }
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nomRessource}.qgs`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading QGS file:', error);
      setError('Error downloading QGS file');
    }
  };


  /** 
   * Vérifie si un email est valide.
   * @param email - Adresse email.
   * @return Vrai si l'adresse email est valide, sinon faux.
    */
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  /** 
   * Vérifie si un numéro de téléphone est valide.
   * @param phone - Numéro de téléphone.
   * @return Vrai si le numéro de téléphone est valide, sinon faux.
    */
  const validatePhone = (phone: string) => {
    const phoneRegex = /^\d{10}$/; // Assuming phone numbers are 10 digits
    return phoneRegex.test(phone);
  };


  /** 
   * Vérifie si un site web est valide.
   * @param website - Site web.
   * @return Vrai si le site web est valide, sinon faux.
   */
  const validateWebsite = (website: string) => {
    const websiteRegex = /^(https?:\/\/)?([\w\-])+(\.[\w\-]+)+[/#?]?.*$/;
    return websiteRegex.test(website);
  };


  /** 
   * Gère le changement de valeur d'un contact (par exemple lors de la création d'un nouveau contact).
   * @param e - Événement de changement.
   */
  const handleNewContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewContact({ ...newContact, [name]: value });

    let errorMessage = '';
    if (name === 'mail_contact' && value && !validateEmail(value)) {
      errorMessage = 'Email invalide';
    } else if (name === 'tel_contact' && value && !validatePhone(value)) {
      errorMessage = 'Numéro de téléphone invalide';
    } else if (name === 'site_contact' && value && !validateWebsite(value)) {
      errorMessage = 'Site web invalide';
    }

    setContactValidationErrors({ ...contactValidationErrors, [name]: errorMessage });
  };

  /** 
   * Crée un nouveau contact et met à jour les listes de fournisseurs ou de producteurs en fonction du type de contact créé.
   */
  const handleCreateContact = async () => {
    if (contactValidationErrors.mail_contact || contactValidationErrors.tel_contact || contactValidationErrors.site_contact) {
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/createContact`, { ...newContact, type_contact: newContactType });
      console.log('Contact created:', response.data);
      const newContactId = response.data.id;
      if (newContactType === 'FOURNISSEUR') {
        setFournisseurs([...fournisseurs, { ...newContact, id: newContactId }]);
        setSelectedFournisseur(newContactId.toString());
        setData({
          ...data,
          id_fournisseur: newContactId,
          mail_fournisseur: newContact.mail_contact,
          tel_fournisseur: newContact.tel_contact,
          site_fournisseur: newContact.site_contact,
        });
      } else if (newContactType === 'PRODUCTEUR') {
        setProducteurs([...producteurs, { ...newContact, id: newContactId }]);
        setSelectedProducteur(newContactId.toString());
        setData({
          ...data,
          id_producteur: newContactId,
          mail_producteur: newContact.mail_contact,
          tel_producteur: newContact.tel_contact,
          site_producteur: newContact.site_contact,
        });
      }
      handleDialogClose();
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  };

  // Affiche un spinner de chargement si les données sont en cours de chargement
  if (loading) {
    return (
      <div>
        <TopBar context="Home" nomRessource='' />
        <div className="loading-container">
          <CircularProgress />
          {loadingMessage && <p className="loading-message">{loadingMessage}</p>}
        </div>
      </div>
    );
  }

  // Affiche un message d'erreur si une erreur s'est produite lors de la récupération des données
  if (error) {
    return (
      <div>
        <TopBar context="Home" nomRessource='' />
        <p className="no-data-error-message">{error}</p>
      </div>
    );
  }

  // Affiche un message si aucune donnée n'est disponible
  if (!data) {
    return (
      <div>
        <TopBar context="Home" nomRessource='' />
        <p className="no-data-error-message">Aucune donnée disponible</p>
      </div>
    );
  }

  // Rendu du formulaire de modification de la fiche de données
  return (
    <div>
      <TopBar context="EditDataSheet" nomRessource={nomRessource} onSave={handleSave} />
      <div className="data-sheet">
        <div className="header">
          <div className="header-left">
            <div className={`input-container ${validationErrors.titre ? 'error' : ''}`}>
              <TextField
                label="Titre *"
                name="titre"
                value={data.titre || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                error={!!validationErrors.titre}
              />
              <div className="error-message">{validationErrors.titre}</div>
            </div>
            <div className={`input-container ${validationErrors.mots_cles ? 'error' : ''}`}>
              <div className="keywords">
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {data.mots_cles && data.mots_cles.map((keyword: string, index: number) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', position: 'relative', marginRight: '5px', width: '221.5px' }}>
                      <Autocomplete
                        freeSolo
                        options={filteredKeywords}
                        inputValue={keyword}
                        onInputChange={(_, newInputValue) => handleKeywordChange(index, newInputValue)}
                        onChange={(_, newValue) => handleKeywordSelect(index, newValue as string)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={index === 0 ? "Mot-clé *" : "Mot-clé"}
                            fullWidth
                            className="input-field"
                            helperText={index === 0 ? `${emojiHelper}Au singulier` : " "}
                            variant="outlined"
                            margin="dense"
                            InputLabelProps={{ className: "input-label" }}
                            inputProps={{ ...params.inputProps, className: "input-input" }}
                            onFocus={handleKeywordFocus}
                            error={!!validationErrors.mots_cles}
                          />
                        )}
                        style={{ marginRight: '0px' }}
                      />
                      <IconButton onClick={() => removeArrayField('mots_cles', index)} style={{ marginTop: '10px' }}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0px', marginTop: '-34px' }}>
                    <IconButton onClick={() => addArrayField('mots_cles')}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className="error-message">{validationErrors.mots_cles}</div>
            </div>
            <div className={`input-container ${validationErrors.description ? 'error' : ''}`}>
              <TextField
                label="Description *"
                name="description"
                value={data.description || ''}
                helperText={`${(data.description || '').length}/1000`}
                onChange={handleChange}
                fullWidth
                multiline
                rows={6}
                className="description-input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "description-input-label" }}
                inputProps={{ maxLength: 1000 }}
                InputProps={{ className: "description-input-root" }}
                error={!!validationErrors.description}
              />
              <div className="error-message">{validationErrors.description}</div>
            </div>
            <div className="input-container">
              <TextField
                label="Subtilité(s)"
                name="subtilites"
                value={data.subtilites || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
              />
            </div>
          </div>
          <div className="header-right">
            <div className="tags">
              <strong><span className="tag">{data.nom_ressource}</span></strong>
            </div>
            <div className="header-buttons-row">
              <button className="open-with-button" onClick={handleOpenWithExcel}><span>Ouvrir avec Excel</span><img src='../img/excel.svg' className="open-with-button-img" /></button>
              {data.type_geo && <button className="open-with-button" onClick={handleOpenWithQGIS}><span>Ouvrir avec QGIS</span><img src='../img/qgis.svg' className="open-with-button-img" /></button>}
            </div>
            <div className="metadata-quality-container">
              <MetadataQuality quality={metadataQuality} fields={fields} />
              <span className="data-card-progress-date">Mis à jour aujourd'hui</span>
            </div>
          </div>
        </div>

        <div className="section">
          <h2 className="section-title">Informations internes</h2>
          <div className="section-content">
            <TextField
              label="Thématique associée"
              name="thematique"
              value={data.thematique || ''}
              disabled
              fullWidth
              className="input-field"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ className: "input-label" }}
              inputProps={{ className: "input-input" }}
            />
            <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
              <InputLabel className="input-label">Observatoire(s) associé(s)</InputLabel>
              <Select
                name="observatoire"
                multiple
                value={data.observatoire || []}
                input={<OutlinedInput label="Observatoire(s) associé(s)" />}
                onChange={(event: SelectChangeEvent<string[]>) => {
                  const selectedObservatoires = event.target.value as string[];
                  setData({ ...data, observatoire: selectedObservatoires });
                }}
                inputProps={{ className: "input-input" }}
              >
              {observatoires.map(obs => (
                <MenuItem key={obs.libelle} value={obs.libelle} style={{ fontSize: '0.975rem', height: '40px' }}>
                  <Checkbox checked={data.observatoire && data.observatoire.indexOf(obs.libelle) > -1} size="small" />
                  {obs.libelle}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
              <InputLabel className="input-label">État des données</InputLabel>
              <Select
                name="etat"
                value={data.etat || ''}
                input={<OutlinedInput label="État des données" />}
                onChange={(event: SelectChangeEvent<string>) => {
                  const selectedEtat = event.target.value;
                  setData({ ...data, etat: selectedEtat });
                }}
                inputProps={{ className: "input-input" }}
              >
                <MenuItem value="">Aucun</MenuItem>
                {etats.map(etat => (
                  <MenuItem key={etat.libelle} value={etat.libelle}>{etat.libelle}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{emojiHelper} Niveau d'enrichissement des données lors de leur intégration</FormHelperText>
            </FormControl>
            {data.etudes_publiees && data.etudes_publiees.map((etude: string, index: number) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', width: '100%' }}>
                <TextField
                  label="Étude publiée"
                  value={etude}
                  onChange={(e) => handleArrayChange('etudes_publiees', index, e.target.value)}
                  fullWidth
                  className="input-field"
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ className: "input-label" }}
                  inputProps={{ className: "input-input" }}
                  error={!!validationErrors.etudes_publiees}
                  helperText={validationErrors.etudes_publiees}
                />
                <IconButton onClick={() => removeArrayField('etudes_publiees', index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <IconButton onClick={() => addArrayField('etudes_publiees')}>
              <AddIcon />
            </IconButton>
          </div>
        </div>

        <div className="section">
          <h2 className="section-title">Informations spatiales</h2>
          <div className="section-content">
          <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
            <InputLabel className="input-label">Emprise spatiale</InputLabel>
              <Select
                name="emprise_spatiale"
                value={data.emprise_spatiale || ''}
                input={<OutlinedInput label="Emprise spatiale" />}
                onChange={(event: SelectChangeEvent<string>) => {
                  const selectedEmprise = event.target.value;
                  setData({ ...data, emprise_spatiale: selectedEmprise });
                }}
                inputProps={{ className: "input-input" }}
              >
                <MenuItem value="">Aucun</MenuItem>
                {emprises.map(emprise => (
                  <MenuItem key={emprise.libelle} value={emprise.libelle}>{emprise.libelle}</MenuItem>
                ))}
              </Select>
            <FormHelperText>{emojiHelper} Territoire de disponibilité de la donnée</FormHelperText>
          </FormControl>
          {views.length > 0 && (
            <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
              <InputLabel className="input-label">Autres emprises disponibles</InputLabel>
              <Select
                name="autres_emprises"
                multiple
                value={data.autres_emprises || []}
                input={<OutlinedInput label="Autres emprises disponibles" />}
                onChange={(event: SelectChangeEvent<string[]>) => setData({ ...data, autres_emprises: event.target.value as string[] })}
                inputProps={{ className: "input-input" }}
              >
              {views.map((view, index) => (
                <MenuItem key={index} value={view}>
                  <Checkbox checked={data.autres_emprises && data.autres_emprises.indexOf(view) > -1} />
                  {view}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          )}


            <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
              <InputLabel className="input-label">Niveau de détail le plus fin</InputLabel>
                <Select
                  name="echelle_min"
                  value={data.echelle_min || ''}
                  input={<OutlinedInput label="Niveau de détail le plus fin" />}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const selectedEchelleMin = event.target.value;
                    setData({ ...data, echelle_min: selectedEchelleMin });
                  }}
                  inputProps={{ className: "input-input" }}
                >
                  <MenuItem value="">Aucun</MenuItem>
                  {echellemin.map(echellemin => (
                    <MenuItem key={echellemin.libelle} value={echellemin.libelle}>{echellemin.libelle}</MenuItem>
                  ))}
                </Select>
              <FormHelperText>{emojiHelper} Niveau de représentation de chaque objet du jeu de données</FormHelperText>
            </FormControl>
            {data.type_geo && (
              <>
                <TextField
                  label="Type de représentation géographique"
                  name="type_geo"
                  value={data.type_geo || ''}
                  disabled
                  fullWidth
                  className="input-field"
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ className: "input-label" }}
                  inputProps={{ className: "input-input" }}
                />
              </>
            )}

            {data.type_geo && (
              <>
                <TextField
                  label="Système de coordonnées"
                  name="systeme_coord"
                  value={data.systeme_coord || ''}
                  disabled
                  fullWidth
                  className="input-field"
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ className: "input-label" }}
                  inputProps={{ className: "input-input" }}
                />
              </>
            )}
          </div>
        </div>


        <div className="section">
          <h2 className="section-title">Informations temporelles</h2>
          <div className="section-content">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date de création"
              value={data.date_first_maj ? dayjs(data.date_first_maj, 'DD/MM/YYYY') : null}
              onChange={(date: Dayjs | null) => handleDateChange('date_first_maj', date)}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  fullWidth: true,
                  helperText: emojiHelper + "Date de première intégration de la donnée",
                  className: "input-field",
                  variant: "outlined",
                  margin: "dense",
                  InputLabelProps: { className: "input-label" }
                }
              }}
            />
            <DatePicker
              label="Date de dernière mise à jour"
              value={data.date_last_maj ? dayjs(data.date_last_maj, 'DD/MM/YYYY') : null}
              onChange={(date: Dayjs | null) => handleDateChange('date_last_maj', date)}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  fullWidth: true,
                  className: "input-field",
                  variant: "outlined",
                  margin: "dense",
                  InputLabelProps: { className: "input-label" }
                }
              }}
            />
            </LocalizationProvider>
            <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
              <InputLabel className="input-label">Fréquence de mise à jour (AudéLor)</InputLabel>
              <Select
                name="freq_maj"
                value={data.freq_maj || ''}
                input={<OutlinedInput label="Fréquence de mise à jour (AudéLor)" />}
                onChange={(event: SelectChangeEvent<string>) => {
                  const selectedFrequenceMaj = event.target.value;
                  setData({ ...data, freq_maj: selectedFrequenceMaj });
                }}
                inputProps={{ className: "input-input" }}
              >
                <MenuItem value="">Aucun</MenuItem>
                {frequence_maj.map(freqMaj => (
                  <MenuItem key={freqMaj.libelle} value={freqMaj.libelle}>{freqMaj.libelle}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
              <InputLabel className="input-label">Fréquence de mise à jour (Fournisseur)</InputLabel>
              <Select
                name="freq_maj_possible"
                value={data.freq_maj_possible || ''}
                input={<OutlinedInput label="Fréquence de mise à jour (Fournisseur)" />}
                onChange={(event: SelectChangeEvent<string[]>) => {
                  const selectedFrequenceMajPossible = event.target.value as string[];
                  setData({ ...data, freq_maj_possible: selectedFrequenceMajPossible });
                }}
                inputProps={{ className: "input-input" }}
              >
                <MenuItem value="">Aucun</MenuItem>
                {frequence_maj_possible.map(freqMajPos => (
                  <MenuItem key={freqMajPos.libelle} value={freqMajPos.libelle}>{freqMajPos.libelle}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date de prochaine mise à jour"
                value={data.date_prochaine_maj ? dayjs(data.date_prochaine_maj, 'DD/MM/YYYY') : null}
                onChange={(date: Dayjs | null) => handleDateChange('date_prochaine_maj', date)}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    className: "input-field",
                    variant: "outlined",
                    margin: "dense",
                    InputLabelProps: { className: "input-label" }
                  }
                }}
              />
            </LocalizationProvider>

            <div className="data-sheet-millesime" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {data.millesimes && data.millesimes.map((millesime: string, index: number) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginBottom: '10px', width: '222px' }}>
                  <TextField
                    label="Millésime disponible"
                    value={millesime}
                    onChange={(e) => handleArrayChange('millesimes', index, e.target.value)}
                    fullWidth
                    className="input-field"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ className: "input-label" }}
                    inputProps={{ className: "input-input" }}
                  />
                  <IconButton onClick={() => removeArrayField('millesimes', index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
              <IconButton onClick={() => addArrayField('millesimes')} style={{ alignSelf: 'center', marginTop: '-10px' }}>
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div className="split-section">
          <div className="section contact">
            <h2 className="section-title">Fournisseur</h2>
            <div className="section-content">
              <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
                <InputLabel className="input-label">Nom du fournisseur</InputLabel>
                <Select
                  name="id_fournisseur"
                  value={selectedFournisseur}
                  input={<OutlinedInput label="Nom du fournisseur" />}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const selectedId = event.target.value;
                    if (selectedId === '') {
                      setSelectedFournisseur('');
                      setData({
                        ...data,
                        id_fournisseur: null,
                        mail_fournisseur: '',
                        tel_fournisseur: '',
                        site_fournisseur: '',
                      });
                    } else if (selectedId === 'new') {
                      handleDialogOpen('FOURNISSEUR');
                    } else {
                      setSelectedFournisseur(selectedId);
                      const selectedContact = fournisseurs.find(contact => contact.id.toString() === selectedId);
                      if (selectedContact) {
                        setData({
                          ...data,
                          id_fournisseur: selectedId,
                          mail_fournisseur: selectedContact?.mail_contact || '',
                          tel_fournisseur: selectedContact?.tel_contact || '',
                          site_fournisseur: selectedContact?.site_contact || '',
                        });
                      }
                    }
                  }}
                  inputProps={{ className: "input-input" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // hauteur de la liste déroulante
                        backgroundColor: 'white'
                      },
                    },
                  }}
                  onClose={() => setSearchFournisseur('')} // effacer la valeur de recherche à la fermeture de la liste déroulante
                >
                  <Box px={2} pt={1} pb={1} style={{ pointerEvents: 'none' }}>
                    <TextField
                      placeholder="Rechercher fournisseur..."
                      value={searchFournisseur}
                      onChange={handleSearchFournisseurChange}
                      fullWidth
                      style={{ pointerEvents: 'auto' }}
                      InputProps={{ style: { fontSize: '0.875rem', height: '45px' } }}
                      onClick={(event) => {
                        event.stopPropagation(); // empêche le déclenchement de la sélection
                      }}
                      onKeyDown={(event) => event.stopPropagation()} // empêche le déclenchement de la sélection
                    />
                  </Box>
                  <MenuItem value="">Aucun</MenuItem>
                  <MenuItem value="new"><b>Nouveau contact...</b></MenuItem>
                  {filteredFournisseurs.map(contact => (
                    <MenuItem key={contact.id} value={contact.id.toString()}>{contact.nom_contact}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Email du fournisseur"
                name="mail_fournisseur"
                value={data.mail_fournisseur || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
                error={!!contactValidationErrors.mail_contact}
                helperText={contactValidationErrors.mail_contact}
              />
              <TextField
                label="Site du fournisseur"
                name="site_fournisseur"
                value={data.site_fournisseur || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
                error={!!contactValidationErrors.site_contact}
                helperText={contactValidationErrors.site_contact}
              />
              <TextField
                label="Téléphone du fournisseur"
                name="tel_fournisseur"
                value={data.tel_fournisseur || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
                error={!!contactValidationErrors.tel_contact}
                helperText={contactValidationErrors.tel_contact}
              />
              <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
                <InputLabel className="input-label">Licence ou convention existante</InputLabel>
                <Select
                  name="licence_existante"
                  value={data.licence_existante || ''}
                  onChange={handleSelectChange}
                  input={<OutlinedInput label="Licence ou convention existante" />}
                  inputProps={{ className: "input-input" }}
                >
                  <MenuItem value="">Aucun</MenuItem>
                  <MenuItem value="Oui">Oui</MenuItem>
                  <MenuItem value="Non">Non</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="section contact">
            <h2 className="section-title">Producteur</h2>
            <div className="section-content">
              <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
                <InputLabel className="input-label">Nom du producteur</InputLabel>
                <Select
                  name="id_producteur"
                  value={selectedProducteur}
                  input={<OutlinedInput label="Nom du producteur" />}
                  onChange={(event: SelectChangeEvent<string>) => {
                    const selectedId = event.target.value;
                    if (selectedId === '') {
                      setSelectedProducteur('');
                      setData({
                        ...data,
                        id_producteur: null,
                        mail_producteur: '',
                        tel_producteur: '',
                        site_producteur: '',
                      });
                    } else if (selectedId === 'new') {
                      handleDialogOpen('PRODUCTEUR');
                    } else {
                      setSelectedProducteur(selectedId);
                      const selectedContact = producteurs.find(contact => contact.id.toString() === selectedId);
                      if (selectedContact) {
                        setData({
                          ...data,
                          id_producteur: selectedId,
                          mail_producteur: selectedContact?.mail_contact || '',
                          tel_producteur: selectedContact?.tel_contact || '',
                          site_producteur: selectedContact?.site_contact || '',
                        });
                      }
                    }
                  }}
                  inputProps={{ className: "input-input" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // hauteur de la liste déroulante
                        backgroundColor: 'white'
                      },
                    },
                  }}
                  onClose={() => setSearchProducteur('')} // effacer la valeur de recherche à la fermeture de la liste déroulante
                >
                  <Box px={2} pt={1} pb={1} style={{ pointerEvents: 'none' }}>
                    <TextField
                      placeholder="Rechercher producteur..."
                      value={searchProducteur}
                      onChange={handleSearchProducteurChange}
                      fullWidth
                      style={{ pointerEvents: 'auto' }}
                      InputProps={{ style: { fontSize: '0.875rem', height: '45px' } }}
                      onClick={(event) => {
                        event.stopPropagation(); // empêche le déclenchement de la sélection
                      }}
                      onKeyDown={(event) => event.stopPropagation()} // empêche le déclenchement de la sélection
                    />
                  </Box>
                  <MenuItem value="">Aucun</MenuItem>
                  <MenuItem value="new"><b>Nouveau contact...</b></MenuItem>
                  {filteredProducteurs.map(contact => (
                    <MenuItem key={contact.id} value={contact.id.toString()}>{contact.nom_contact}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Email du producteur"
                name="mail_producteur"
                value={data.mail_producteur || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
                error={!!contactValidationErrors.mail_contact}
                helperText={contactValidationErrors.mail_contact}
              />
              <TextField
                label="Site du producteur"
                name="site_producteur"
                value={data.site_producteur || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
                error={!!contactValidationErrors.site_contact}
                helperText={contactValidationErrors.site_contact}
              />
              <TextField
                label="Téléphone du producteur"
                name="tel_producteur"
                value={data.tel_producteur || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
                error={!!contactValidationErrors.tel_contact}
                helperText={contactValidationErrors.tel_contact}
              />
              <TextField
                label="Lien vers la donnée brute"
                name="lien_donnees_brut"
                value={data.lien_donnees_brut || ''}
                onChange={handleChange}
                fullWidth
                className="input-field"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ className: "input-label" }}
                inputProps={{ className: "input-input" }}
                error={!!validationErrors.lien_donnees_brut}
                helperText={validationErrors.lien_donnees_brut}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <h2 className="section-title">Référent(s)</h2>
          <div className="section-content">
            <FormControl fullWidth variant="outlined" margin="dense" className="input-field">
              <InputLabel className="input-label">Chargé·e·s d'études associé·e·s</InputLabel>
              <Select
                name="id_ce_aud"
                multiple
                value={selectedCeAud}
                input={<OutlinedInput label="Chargé·e·s d'études associé·e·s" />}
                onChange={handleCeAudChange}
                inputProps={{ className: "input-input" }}
                renderValue={(selected) => (
                  <div>
                    {selected.map(value => ceAud.find(ce => ce.id.toString() === value)?.nom_ce || '').join(', ')}
                  </div>
                )}
              >
              {ceAud.map(ce => (
                <MenuItem key={ce.id} value={ce.id.toString()} style={{ fontSize: '0.975rem', height: '40px' }} >
                  <Checkbox checked={selectedCeAud.indexOf(ce.id.toString()) > -1} size="small" />
                  {ce.nom_ce}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
            <TextField
              label="Email(s) chargé·e·s d'études associé·e·s"
              name="mail_ce_aud"
              value={(Array.isArray(data.mail_ce_aud) ? data.mail_ce_aud.join(', ') : data.mail_ce_aud) || ''}
              onChange={handleChange}
              fullWidth
              disabled
              className="input-field"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ className: "input-label" }}
              inputProps={{ className: "input-input" }}
            />
            <TextField
              label="Téléphone(s) chargé·e·s d'études associé·e·s"
              name="tel_ce_aud"
              value={(Array.isArray(data.tel_ce_aud) ? data.tel_ce_aud.map(formatPhoneNumber).join(', ') : formatPhoneNumber(data.tel_ce_aud)) || ''}
              onChange={handleChange}
              fullWidth
              disabled
              className="input-field"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ className: "input-label" }}
              inputProps={{ className: "input-input" }}
            />
          </div>
        </div>

        <div className="section">
          <h2 className="section-title">Aperçu de la table</h2>
          <div className="section-content table-preview">
            <TextField
              label="Lien vers un dictionnaire"
              name="lien_dictionnaire"
              value={data.lien_dictionnaire || ''}
              onChange={handleChange}
              fullWidth
              className="input-field"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ className: "input-label" }}
              inputProps={{ className: "input-input" }}
              error={!!validationErrors.lien_dictionnaire}
              helperText={validationErrors.lien_dictionnaire}
            />
            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    {data.columns && data.columns.map((column: string, index: number) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.tablePreview && data.tablePreview.map((row: any, rowIndex: number) => (
                    <tr key={rowIndex}>
                      {data.columns && data.columns.map((column: string, colIndex: number) => (
                        <td key={colIndex}>{row[column]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Créer un nouveau contact</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            name="nom_contact"
            value={newContact.nom_contact}
            onChange={handleNewContactChange}
            fullWidth
            margin="dense"
            variant="outlined"
            helperText={`${emojiHelper} S'il s'agit d'une personne : Prénom NOM - Instance (Virginie LE TEXIER - Lorient Agglomération)`}
          />
          <TextField
            label="Email"
            name="mail_contact"
            value={newContact.mail_contact}
            onChange={handleNewContactChange}
            fullWidth
            margin="dense"
            variant="outlined"
            error={!!contactValidationErrors.mail_contact}
            helperText={contactValidationErrors.mail_contact}
          />
          <TextField
            label="Téléphone"
            name="tel_contact"
            value={newContact.tel_contact}
            onChange={handleNewContactChange}
            fullWidth
            margin="dense"
            variant="outlined"
            error={!!contactValidationErrors.tel_contact}
            helperText={contactValidationErrors.tel_contact}
          />
          <TextField
            label="Site web"
            name="site_contact"
            value={newContact.site_contact}
            onChange={handleNewContactChange}
            fullWidth
            margin="dense"
            variant="outlined"
            error={!!contactValidationErrors.site_contact}
            helperText={contactValidationErrors.site_contact}
          />
          <TextField
            label="Type de contact"
            name="type_contact"
            value={newContactType}
            fullWidth
            margin="dense"
            variant="outlined"
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} style={{ color: 'black' }}>Annuler</Button>
          <Button onClick={handleCreateContact} style={{ color: 'black' }}>Créer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditDataSheet;
